import React from 'react';
import { useDrag } from 'react-dnd';

/**
 * If table capacity <= 2, we show two-letter initials for the *inner text*
 * on the table surface. Example: "Thibault Van de Sompele" => "TV"
 */
function getTinyInitials(firstName, lastName) {
  const f = firstName?.[0]?.toUpperCase() || '';
  // Remove any variation of "van de" from the last name for the initial:
  const strippedLastName = lastName.replace(/\bvan\W+de\b/gi, '').trim();
  const l = strippedLastName?.[0]?.toUpperCase() || '';
  return f + l;
}

/**
 * Normal format for bigger tables:
 * e.g. "Thibault Van de Sompele" => "T. vd Sompele"
 */
function formatOccupiedName(firstName, lastName) {
  const firstInitial = firstName?.[0]?.toUpperCase() || '';
  // Replace any variation of "van de" with "vd"
  const formattedLastName = lastName.replace(/\bvan\W+de\b/gi, 'vd');
  return `${firstInitial}. ${formattedLastName}`.trim();
}

/**
 * Child component for each reservation.
 * Allows dragging reservations off the table if needed.
 */
const Reservation = ({
  reservation,
  removeReservation,
  updateNotes,
  tableCapacity,
}) => {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'GUEST',
      item: { id: reservation.id, ...reservation },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [reservation]
  );

  // Decide how to display the name ON THE TABLE:
  let displayedName = '';
  if (tableCapacity <= 2) {
    // For a 1- or 2-person table, show "TV"
    displayedName = getTinyInitials(reservation.firstName, reservation.lastName);
  } else {
    // Otherwise, fallback to normal style (e.g., "T. vd Sompele")
    displayedName = formatOccupiedName(reservation.firstName, reservation.lastName);
  }

  return (
    <div
      className="floor-plan-page reservation"
      ref={dragRef}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab' }}
    >
      <span className="floor-plan-page reservation-name">{displayedName}</span>{' '}
      {reservation.numberOfGuests}p - {reservation.time}
    </div>
  );
};

export default Reservation;
