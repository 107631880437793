// file-path: ./GuestBar.js
import React, { useState, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import useReservations from './Hooks/useReservations';
import './css/guestbar.css';

// Simple map for colors based on numberOfGuests.
// You can adjust or expand this map as needed.
const colorMap = {
  1: '#FFBDBD', // e.g., red-ish
  2: '#FFD0BD', // orange-ish
  3: '#FFFABD', // yellow-ish
  4: '#BDFFBE', // green-ish
  5: '#BDEAFF', // blue-ish
  6: '#DABDFF', // purple-ish
  7: '#FFBDDB', // pink-ish
  8: '#C8F7C5', // light green
  9: '#F5BDC8', // light red/pink
  10: '#AED4F2', // light blue
};

const getColorForGuests = (guestCount) => {
  return colorMap[guestCount] || '#ccc'; // fallback color if not in map
};

const GuestBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // Fetch reservations from the custom hook
  const reservations = useReservations();

  // Filter unassigned, match search, then sort by numberOfGuests
  const filteredReservations = useMemo(() => {
    const lowerSearch = searchTerm.toLowerCase();
    return reservations
      .filter(
        (guest) =>
          guest.tableId === null &&
          (guest.firstName.toLowerCase().includes(lowerSearch) ||
           guest.lastName.toLowerCase().includes(lowerSearch) ||
           guest.numberOfGuests.toString().includes(lowerSearch) ||
           guest.time.includes(lowerSearch))
      )
      .sort((a, b) => a.numberOfGuests - b.numberOfGuests);
  }, [searchTerm, reservations]);

  // Draggable Guest Block
  const GuestBlock = ({ guest }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'GUEST',
      item: { id: guest.id, ...guest },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }), [guest]);

    // Avatar color based on number of guests
    const avatarColor = getColorForGuests(guest.numberOfGuests);

    return (
      <div
        className="table-plan-component card"
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <div className="table-plan-component card-content">
          <div
            className="table-plan-component avatar"
            style={{ backgroundColor: avatarColor }}
          >
            {/* Show the number of guests in the avatar */}
            {guest.numberOfGuests}
          </div>
          {/* Separate container for text, allowing wrapping */}
          <div className="table-plan-component guest-info">
            <h3 style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
              {guest.firstName} {guest.lastName}
            </h3>
            <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>
              {guest.numberOfGuests}p • {guest.time}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="table-plan-component guest-bar-container">
      <div className="table-plan-component guest-search-bar">
        <input
          type="text"
          placeholder="Zoek gasten..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          aria-label="Zoek gasten"
        />
      </div>
      <div className="table-plan-component guests-list">
        {filteredReservations.length > 0 ? (
          filteredReservations.map((guest) => (
            <GuestBlock key={guest.id} guest={guest} />
          ))
        ) : (
          <p className="table-plan-component no-results">Geen gasten gevonden.</p>
        )}
      </div>
    </div>
  );
};

export default GuestBar;
