// file-path: ./Sidebar.js
import React, { useState, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { motion } from 'framer-motion';
import { FaChevronRight } from 'react-icons/fa'; // Added for the toggle button
import Table from './Table.js';
import Walls from './Walls.js';
import GuestBar from './GuestBar.js';
import './css/sidebar.css';

const TableItem = ({ table }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ITEM',
      item: {
        elementType: 'table',
        subtype: 'round',
        width: 70,
        height: 70,
        capacity: table.numberOfGuests,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [table]
  );

  return (
    <div
      className="table-plan-component item"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {/* Pass showActions={false} to hide action buttons in Sidebar */}
      <Table numberOfGuests={table.numberOfGuests} showActions={false} />
      <div className="item-info">
        <p>Tafel {table.id}</p>
        <p>Gasten: {table.numberOfGuests}</p>
      </div>
    </div>
  );
};

const WallItem = ({ wall }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'ITEM',
      item: {
        elementType: 'wall',
        subtype: 'wall',
        width: wall.length * 20,
        height: 20,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [wall]
  );

  return (
    <div
      className="item"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <Walls length={wall.length} />
      <div className="item-info">
        <p>Muur {wall.id}</p>
        <p>Lengte: {wall.length} eenheden</p>
      </div>
    </div>
  );
};

const Sidebar = ({ tables, walls, isOpen, onToggle }) => {
  // Make 'guests' the default active tab so GuestBar shows first
  const [activeTab, setActiveTab] = useState('guests');
  const [searchTerm, setSearchTerm] = useState('');
  const [pendingTab, setPendingTab] = useState(null);

  const tablesRef = useRef();
  const wallsRef = useRef();
  const isIframe = typeof window !== 'undefined' && window.isIframe;

  // Reorder tabs so that 'guests' is first
  const tabs = [
    { id: 'guests', label: 'Gasten', title: 'Beheer Gasten' },
    { id: 'tables', label: 'Tafels', title: 'Beheer Tafels' },
    { id: 'walls', label: 'Muren', title: 'Beheer Muren' },
  ];

  // Filter tables based on searchTerm
  const filteredTables = tables.filter(
    (table) =>
      table.id.toString().includes(searchTerm) ||
      table.numberOfGuests.toString().includes(searchTerm)
  );

  // Filter walls based on searchTerm
  const filteredWalls = walls.filter(
    (wall) =>
      wall.id.toString().includes(searchTerm) ||
      wall.length.toString().includes(searchTerm)
  );

  // Handle switching tabs, ensuring data is saved if isDirty
  const handleTabClick = async (tabId, tabTitle) => {
    let currentRef;
    if (activeTab === 'tables') {
      currentRef = tablesRef;
    } else if (activeTab === 'walls') {
      currentRef = wallsRef;
    }
    // If there's a dirty form in the current tab (tables/walls),
    // handle saving before switching
    if (currentRef && currentRef.current && currentRef.current.isDirty) {
      if (isIframe) {
        try {
          await currentRef.current.handleSave();
          setActiveTab(tabId);
        } catch (error) {
          console.error('Error saving before tab switch:', error);
        }
      } else {
        setPendingTab({ id: tabId, title: tabTitle });
      }
    } else {
      setActiveTab(tabId);
    }
  };

  return (
    <div className="table-plan-component">
      {/* Toggle Button */}
      <div className="toggle-button-component">
        <div
          className={`toggle-button ${isOpen ? 'open' : 'closed'}`}
          onClick={onToggle}
          aria-label={isOpen ? 'Close sidebar' : 'Open sidebar'}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onToggle();
            }
          }}
        >
          <FaChevronRight className={`chevron ${isOpen ? 'rotated' : ''}`} />
        </div>
      </div>

      {/* Sidebar */}
      <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <div className="tabs">
          <div className="buttons-container">
            {tabs.map((tab) => (
              <motion.button
                key={tab.id}
                type="button"
                className={`tab-button ${
                  activeTab === tab.id ? 'active' : ''
                }`}
                onClick={() => handleTabClick(tab.id, tab.title)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="tab-label">{tab.label}</span>
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="underline-sidebar-tabs"
                    className="tab-underline"
                    initial={false}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                )}
              </motion.button>
            ))}
          </div>
        </div>

        {/* Only show this search bar for Tables/Walls */}
        {activeTab === 'tables' && (
          <div className="search-bar">
            <input
              type="text"
              placeholder="Zoek Tafels..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Zoek Tafels"
            />
          </div>
        )}

        {activeTab === 'walls' && (
          <div className="search-bar">
            <input
              type="text"
              placeholder="Zoek Muren..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Zoek Muren"
            />
          </div>
        )}

        <div className="items-list">
          {/* Guests tab -> Render GuestBar */}
          {activeTab === 'guests' && (
            <div className="guests-tab">
              <GuestBar />
            </div>
          )}

          {/* Tables tab */}
          {activeTab === 'tables' && filteredTables.length > 0 ? (
            <div className="grid-container">
              {filteredTables.map((table) => (
                <TableItem key={table.id} table={table} />
              ))}
            </div>
          ) : activeTab === 'tables' && filteredTables.length === 0 ? (
            <p className="no-results">Geen tafels gevonden.</p>
          ) : null}

          {/* Walls tab */}
          {activeTab === 'walls' && filteredWalls.length > 0 ? (
            <div className="grid-container">
              {filteredWalls.map((wall) => (
                <WallItem key={wall.id} wall={wall} />
              ))}
            </div>
          ) : activeTab === 'walls' && filteredWalls.length === 0 ? (
            <p className="no-results">Geen muren gevonden.</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
