// src/components/GiftcardSettings/GeneralSettingsGiftcard.jsx

import React from 'react';

const GeneralSettingsGiftcard = ({ settings, handleChange, handleSave, isDirty }) => {

  return (
    <form className="giftcard-settings-form" onSubmit={handleSave} noValidate>
      <div className="giftcard-form-group">
        <label>Titel</label>
        <div className="giftcard-input-container">
          <input
            type="text"
            name="title"
            value={settings.title}
            onChange={handleChange}
            placeholder="Voer de titel in"
            required
          />
        </div>
      </div>

      {/* Optional: Add a color picker for titleColor */}
      <div className="giftcard-form-group">
        <label>Titel Kleur</label>
        <div className="giftcard-input-container">
          <input
            type="color"
            name="titleColor"
            value={settings.titleColor}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="giftcard-form-group">
        <label>Begroeting</label>
        <div className="giftcard-input-container">
          <input
            type="text"
            name="greeting"
            value={settings.greeting}
            onChange={handleChange}
            placeholder="Voer de begroeting in"
            required
          />
        </div>
      </div>

      <div className="giftcard-form-group">
        <label>Intro Tekst</label>
        <div className="giftcard-input-container">
          <textarea
            name="introText"
            value={settings.introText}
            onChange={handleChange}
            placeholder="Voer de introductietekst in"
            required
          />
        </div>
      </div>

      <div className="giftcard-form-group">
        <label>Midden Tekst</label>
        <div className="giftcard-input-container">
          <textarea
            name="middleText"
            value={settings.middleText}
            onChange={handleChange}
            placeholder="Voer de middentekst in"
            required
          />
        </div>
      </div>

      <div className="giftcard-form-group">
        <label>Eind Tekst</label>
        <div className="giftcard-input-container">
          <textarea
            name="endText"
            value={settings.endText}
            onChange={handleChange}
            placeholder="Voer de eindtekst in"
            required
          />
        </div>
      </div>

      <div className="giftcard-form-group">
        <label>Logo Positie</label>
        <div className="giftcard-input-container">
          <select
            name="logoPosition"
            value={settings.logoPosition}
            onChange={handleChange}
            required
          >
            <option value="left">Links</option>
            <option value="center">Midden</option>
            <option value="right">Rechts</option>
          </select>
        </div>
      </div>

      <button type="submit" className="giftcard-settings-button" disabled={!isDirty}>
        Opslaan
      </button>
    </form>
  );
};

export default GeneralSettingsGiftcard;
