import React, { useState, useEffect } from 'react';
import ValueSelectorGuests from './ValueSelector';
import DateSelector from './DateSelector';
import TimeSelector from './TimeSelector';
import './css/reservationMode.css'; // Non-mobile CSS for reservation mode
import moment from 'moment';
import useApi from '../../../Hooks/useApi'; // Import useApi
import { isWeekInPast } from './Utils/dateUtils';

const ReservationStepOne = ({
  formData,
  errors,
  handleChange,
  handleStepOneSubmit,
  setFormData,
  timeblocks,
  loadingTimeblocks,
  timeblocksError,
  restaurantData,
}) => {
  const [guests, setGuests] = useState(1);
  const [startDate, setStartDate] = useState(null); // startDate state
  const [reservations, setReservations] = useState([]); // reservations state
  const api = useApi(); // Initialize useApi hook

  const resetFormDataFields = (fieldsToReset) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      fieldsToReset.forEach((field) => {
        newFormData[field] = '';
      });
      return newFormData;
    });
  };

  // Initialize startDate
  useEffect(() => {
    const today = moment().tz('Europe/Amsterdam').startOf('day');
    let firstWeekStart = today.clone().startOf('isoWeek');
    while (isWeekInPast(firstWeekStart)) {
      firstWeekStart.add(1, 'week');
    }
    setStartDate(firstWeekStart);
  }, []);

  // Fetch reservations when startDate changes (for met_limieten and zonder_regels)
  useEffect(() => {
    const fetchReservations = async () => {
      if (startDate && (formData.reservationMode === 'met_limieten' || formData.reservationMode === 'zonder_regels')) {
        const beginDate = startDate.format('YYYY-MM-DD');
        const endDate = startDate.clone().add(13, 'days').format('YYYY-MM-DD');
        const restaurantId = localStorage.getItem('username');
        const endpoint = `${window.baseDomain}api/slots/${restaurantId}/${beginDate}/${endDate}`;

        try {
          console.log('Calendar Slots GET');
          const data = await api.get(endpoint, { noCache: true });
          setReservations(data); // Update reservations state
          console.log('Fetched reservations:', data);
        } catch (error) {
          console.error('Error fetching reservations:', error);
        }
      } else {
        // For modes without reservation checks (vrije_keuze or no final mode), no reservations needed
        setReservations([]);
      }
    };

    fetchReservations();
  }, [startDate, api, formData.reservationMode]);

  // Handler for week change
  const handleWeekChange = (newStartDate) => {
    setStartDate(newStartDate);
  };

  // Set default reservation mode to 'met_limieten' on component mount if not set
  useEffect(() => {
    if (!formData.reservationMode) {
      handleChange({
        target: { name: 'reservationMode', value: 'met_limieten' },
      });
    }
  }, [formData.reservationMode, handleChange]);

  useEffect(() => {
    resetFormDataFields(['date', 'time']);
  }, [guests]);

  if (timeblocksError) {
    return (
      <div>
        <a
          href="https://dashboard.reservaties.net/#/scheme"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'var(--color-blue)', textDecoration: 'underline' }}
        >
          Klik hier
        </a>{' '}
        om uw openingsuren in te stellen.
      </div>
    );
  }

  // Handler for top-level selection
  const handleTopLevelSelection = (mode) => {
    if (mode === 'met_limieten') {
      handleChange({ target: { name: 'reservationMode', value: 'met_limieten' } });
      resetFormDataFields(['date', 'time']);
    } else if (mode === 'onbeperkt_parent') {
      // Set the mode to 'onbeperkt_parent' to show sub-options
      // If currently in a sub-mode, going back to onbeperkt_parent doesn't make sense,
      // so just always set and reset fields.
      handleChange({ target: { name: 'reservationMode', value: 'onbeperkt_parent' } });
      resetFormDataFields(['date', 'time']);
    }
  };

  // Handler for second-level selection
  const handleSubSelection = (mode) => {
    // mode will be 'zonder_regels' or 'vrije_keuze'
    handleChange({ target: { name: 'reservationMode', value: mode } });
    resetFormDataFields(['date', 'time']);
  };

  const currentMode = formData.reservationMode;

  // isFinalMode: Only show date/time selectors if a final mode is chosen
  const isFinalMode = ['met_limieten', 'zonder_regels', 'vrije_keuze'].includes(currentMode);

  const onbeperktActive = currentMode === 'onbeperkt_parent' 
                        || currentMode === 'zonder_regels' 
                        || currentMode === 'vrije_keuze';

  return (
    <form className="account-manage-form" onSubmit={handleStepOneSubmit} noValidate>
      {/* Reservation Mode Selection */}
      <div className="form-group reservation-mode">
        <div className="reservation-modes-container">
          {/* Top-level modes */}
          <button
            type="button"
            className={`reservation-mode-button ${
              currentMode === 'met_limieten' ? 'active' : ''
            }`}
            onClick={() => handleTopLevelSelection('met_limieten')}
            aria-pressed={currentMode === 'met_limieten'}
          >
            Met Limieten
          </button>
          <button
            type="button"
            className={`reservation-mode-button ${
              onbeperktActive ? 'active' : ''
            }`}
            onClick={() => handleTopLevelSelection('onbeperkt_parent')}
            aria-pressed={onbeperktActive}
          >
            Onbeperkt
          </button>
        </div>

        {/* Show second-level choices if Onbeperkt or one of its sub-modes is selected */}
        {(onbeperktActive) && (
          <div className="reservation-modes-container">
            <button
              type="button"
              className={`reservation-mode-button ${
                currentMode === 'zonder_regels' ? 'active' : ''
              }`}
              onClick={() => handleSubSelection('zonder_regels')}
              aria-pressed={currentMode === 'zonder_regels'}
            >
              Openingsuren
            </button>
            <button
              type="button"
              className={`reservation-mode-button ${
                currentMode === 'vrije_keuze' ? 'active' : ''
              }`}
              onClick={() => handleSubSelection('vrije_keuze')}
              aria-pressed={currentMode === 'vrije_keuze'}
            >
              Vrij
            </button>
          </div>
        )}
      </div>

      <ValueSelectorGuests
        setGuests={setGuests}
        value={formData.guests}
        onChange={handleChange}
        error={errors.guests}
      />

      {/* Show date selector only if a final mode is chosen */}
      {isFinalMode && formData.guests && (
        <DateSelector
          guests={formData.guests}
          formData={formData}
          handleChange={handleChange}
          resetFormDataFields={resetFormDataFields}
          timeblocks={timeblocks}
          restaurantData={restaurantData}
          reservations={reservations}
          startDate={startDate}
          onWeekChange={handleWeekChange}
          reservationMode={currentMode}
        />
      )}

      {/* Show time selector only if a final mode and a date is chosen */}
      {isFinalMode && formData.date && (
        <TimeSelector
          guests={formData.guests}
          formData={formData}
          handleChange={handleChange}
          field={{ id: 'time', label: 'Tijd' }}
          selectedDate={formData.date}
          setCurrentExpandedField={() => {}}
          restaurantData={restaurantData}
          reservations={reservations}
          reservationMode={currentMode}
        />
      )}
    </form>
  );
};

export default ReservationStepOne;
