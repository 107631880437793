// file-path: /src/Components/ReservationsList/Tooltip.jsx
import React, { useEffect, useRef, useState } from 'react';
import {
  FaEllipsisV,
  FaPencilAlt,
  FaTrashAlt,
  FaStickyNote,
  FaBirthdayCake,
  FaUserFriends,
  FaClock,
} from 'react-icons/fa';
import ConfirmationModal from '../../../../Components/Structural/Modal/Delete';
import ModalWithoutTabs from '../../../../Components/Structural/Modal/Standard';
import useApi from '../../../../Hooks/useApi';
import './css/tooltip.css'; // The original tooltip.css with minimal additions

const Tooltip = ({
  reservationId,
  extraInfo,
  isTooltipOpen,
  onTooltipToggle,
  onTooltipClose,
  onDeleteSuccess,
  triggerNotification,
  currentGuests,
  currentDate = '', // Passed from ReservationRow
  currentTime = '', // Passed from ReservationRow
}) => {
  const tooltipTimerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [isParentHovered, setIsParentHovered] = useState(false);
  const [isEllipsisHovered, setIsEllipsisHovered] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  // Guests Modal
  const [isGuestsModalOpen, setIsGuestsModalOpen] = useState(false);
  const [newGuestsNumber, setNewGuestsNumber] = useState(currentGuests || 1);
  const [isUpdatingGuests, setIsUpdatingGuests] = useState(false);
  const [updateGuestsError, setUpdateGuestsError] = useState(null);

  // Date/Time Modal
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);

  // Instead of storing the date/time from props on mount, we store them only when opening the modal
  const [newDate, setNewDate] = useState('');
  const [newTime, setNewTime] = useState('');
  const [isUpdatingDateTime, setIsUpdatingDateTime] = useState(false);
  const [updateDateTimeError, setUpdateDateTimeError] = useState(null);

  const api = useApi();

  const handleEllipsisClick = () => {
    onTooltipToggle(reservationId);
  };

  const handleExtraIconMouseEnter = () => {
    setIsIconHovered(true);
  };

  const handleExtraIconMouseLeave = () => {
    setIsIconHovered(false);
  };

  // Close tooltip after 2.5 seconds when it’s open
  useEffect(() => {
    if (isTooltipOpen) {
      if (tooltipTimerRef.current) {
        clearTimeout(tooltipTimerRef.current);
      }
      tooltipTimerRef.current = setTimeout(() => {
        onTooltipClose();
      }, 2500);
    } else {
      if (tooltipTimerRef.current) {
        clearTimeout(tooltipTimerRef.current);
        tooltipTimerRef.current = null;
      }
    }

    return () => {
      if (tooltipTimerRef.current) {
        clearTimeout(tooltipTimerRef.current);
      }
    };
  }, [isTooltipOpen, onTooltipClose]);

  // Track hovering over parent element
  useEffect(() => {
    const currentTooltip = tooltipRef.current;
    if (!currentTooltip) return;

    const parentElement = currentTooltip.parentElement;
    if (!parentElement) return;

    const handleParentMouseEnter = () => {
      setIsParentHovered(true);
    };

    const handleParentMouseLeave = () => {
      setIsParentHovered(false);
    };

    parentElement.addEventListener('mouseenter', handleParentMouseEnter);
    parentElement.addEventListener('mouseleave', handleParentMouseLeave);

    return () => {
      parentElement.removeEventListener('mouseenter', handleParentMouseEnter);
      parentElement.removeEventListener('mouseleave', handleParentMouseLeave);
    };
  }, []);

  // Decide if we show the special icon
  const shouldShowExtraIcon = extraInfo && extraInfo.trim() !== '';
  const birthdayWords = [
    'birthday',
    'anniversary',
    'anniversaire',
    'geburtstag',
    'jahrestag',
    'jarig',
    'jarige',
    'verjaardag',
    'verjaardagsfeest',
    'jubileum',
    'cumpleaños',
    'aniversario',
  ];
  let iconToUse = FaStickyNote;
  if (shouldShowExtraIcon) {
    const extraInfoLower = extraInfo.toLowerCase();
    const containsBirthdayWord = birthdayWords.some((word) =>
      extraInfoLower.includes(word)
    );
    if (containsBirthdayWord) {
      iconToUse = FaBirthdayCake;
    }
  }

  // External link to an edit page
  const editUrl =
    `https://view.reservaties.net/?action=edit&reservationId=${encodeURIComponent(
      reservationId
    )}&admin=true&restaurantId=${localStorage.getItem('username')}`;

  // Delete flow
  const handleDeleteClick = (e) => {
    e.preventDefault();
    setDeleteError(null);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleteModalVisible(false);
    setIsDeleting(true);
    setDeleteError(null);

    try {
      await api.delete(`${window.baseDomain}api/auth-reservations/${reservationId}`);
      if (onDeleteSuccess) {
        onDeleteSuccess(reservationId);
      }
    } catch (error) {
      console.error('Error deleting reservation:', error);
      setDeleteError(
        error.response?.data?.error || error.message || 'Failed to delete the reservation.'
      );
      if (triggerNotification) {
        triggerNotification('Fout bij het verwijderen van de reservatie.', 'error');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  // Only show the extra tooltip if icon or parent is hovered
  const isExtraTooltipOpen =
    (isIconHovered || isParentHovered) &&
    !isTooltipOpen &&
    !isEllipsisHovered;

  // Guests Modal
  const handleGuestsModalClose = () => {
    setIsGuestsModalOpen(false);
    setUpdateGuestsError(null);
  };

  const handleGuestsUpdate = async () => {
    setIsUpdatingGuests(true);
    setUpdateGuestsError(null);
    try {
      await api.put(`${window.baseDomain}api/auth-reservations/${reservationId}`, {
        guests: newGuestsNumber,
      });
      triggerNotification('Gasten aantal succesvol bijgewerkt', 'success');
      window.location.reload();
    } catch (error) {
      console.error('Error updating guests number:', error);
      setUpdateGuestsError(
        error.response?.data?.error || error.message || 'Failed to update the number of guests.'
      );
      triggerNotification('Fout bij het bijwerken van gasten aantal.', 'error');
    } finally {
      setIsUpdatingGuests(false);
    }
  };

  // Date/Time Modal: we open it by resetting local state from props
  const handleOpenDateTimeModal = (e) => {
    e.preventDefault();
    // Always reset local date/time to the current props
    setNewDate(currentDate || '');
    setNewTime(currentTime || '');
    setIsDateTimeModalOpen(true);
  };

  const handleDateTimeModalClose = () => {
    setIsDateTimeModalOpen(false);
    setUpdateDateTimeError(null);
  };

  const handleDateTimeUpdate = async () => {
    setIsUpdatingDateTime(true);
    setUpdateDateTimeError(null);

    try {
      await api.put(`${window.baseDomain}api/auth-reservations/${reservationId}`, {
        date: newDate,
        time: newTime,
      });
      triggerNotification('Datum en tijd succesvol bijgewerkt', 'success');
      window.location.reload();
    } catch (error) {
      console.error('Error updating date/time:', error);
      setUpdateDateTimeError(
        error.response?.data?.error || error.message || 'Failed to update date/time.'
      );
      triggerNotification('Fout bij het bijwerken van datum of tijd.', 'error');
    } finally {
      setIsUpdatingDateTime(false);
    }
  };

  return (
    <div className="reservations-page extra-column" ref={tooltipRef}>
      <div className="reservations-page icons-container">
        {shouldShowExtraIcon && (
          <div
            className="reservations-page extra-icon-container"
            onMouseEnter={handleExtraIconMouseEnter}
            onMouseLeave={handleExtraIconMouseLeave}
          >
            {React.createElement(iconToUse, {
              className: 'reservations-page extra-icon',
            })}
            {isExtraTooltipOpen && (
              <div className="reservations-page extra-tooltip">{extraInfo}</div>
            )}
          </div>
        )}

        <div
          className="reservations-page ellipsis-container"
          onMouseEnter={() => setIsEllipsisHovered(true)}
          onMouseLeave={() => setIsEllipsisHovered(false)}
        >
          <FaEllipsisV
            className="reservations-page ellipsis-icon"
            onClick={handleEllipsisClick}
          />
          {isTooltipOpen && (
            <div className="reservations-page tooltip-container">
              {/* Extern Bewerken */}
             
              {/* Gasten */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsGuestsModalOpen(true);
                }}
                className="reservations-page tooltip-item gasten-item no-style"
              >
                <FaUserFriends className="reservations-page tooltip-icon" />
                Gasten
              </a>
              <div className="reservations-page tooltip-separator"></div>

              {/* Datum & Tijd */}
              <a
                href="#"
                onClick={handleOpenDateTimeModal}
                className="reservations-page tooltip-item datum-tijd-item no-style"
              >
                <FaClock className="reservations-page tooltip-icon" />
                Datum & Tijd
              </a>
              <div className="reservations-page tooltip-separator"></div>
              <a
                href={editUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="reservations-page tooltip-item no-style"
              >
                <FaPencilAlt className="reservations-page tooltip-icon" />
                Bewerklink
              </a>
              <div className="reservations-page tooltip-separator"></div>

              {/* Verwijderen */}
              <a
                href="#"
                onClick={handleDeleteClick}
                className="reservations-page tooltip-item delete-item no-style"
              >
                <FaTrashAlt className="reservations-page tooltip-icon" />
                Verwijderen
              </a>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        isVisible={isDeleteModalVisible}
        title="Reservatie Verwijderen"
        message="Wilt u deze reservatie verwijderen?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        confirmText="Verwijderen"
        cancelText="Annuleren"
        confirmButtonClass="discard-button red"
        cancelButtonClass="cancel-button"
        isLoading={isDeleting}
        errorMessage={deleteError}
      />

      {/* Guests Modal */}
      {isGuestsModalOpen && (
        <ModalWithoutTabs
          onClose={handleGuestsModalClose}
          content={
            <div>
              <h2 className="secondary-title">Aantal Gasten Bewerken</h2>
              {/* Dutch notice about ignoring any potential limits */}
              <div style={{ fontSize: '0.9rem', color: '#666', marginBottom: '1rem' }}>
                U kan hier het gastenaantal aanpassen zonder beperkingen. Gebruik de bewerklink om te bewerken met limieten.
              </div>
              <div className="input-container" style={{ marginBottom: '1rem' }}>
                <label style={{ fontWeight: '500', marginRight: '0.5rem' }}>
                  Aantal gasten:
                </label>
                <input
                  type="number"
                  min="1"
                  value={newGuestsNumber}
                  onChange={(e) => setNewGuestsNumber(Number(e.target.value))}
                />
              </div>
              {updateGuestsError && (
                <div style={{ color: 'red', marginBottom: '0.5rem' }}>
                  {updateGuestsError}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <button
                  className="button-style-3"
                  onClick={handleGuestsUpdate}
                  disabled={isUpdatingGuests}
                >
                  {isUpdatingGuests ? 'Bezig...' : 'Opslaan'}
                </button>
              </div>
            </div>
          }
        />
      )}

      {/* Date/Time Modal */}
      {isDateTimeModalOpen && (
        <ModalWithoutTabs
          onClose={handleDateTimeModalClose}
          content={
            <div>
              <h2 className="secondary-title">Datum & Tijd Bewerken</h2>
              {/* Dutch notice about ignoring restaurant hours */}
              <div style={{ fontSize: '0.9rem', color: '#666', marginBottom: '1rem' }}>
              U kan hier de datum en tijd aanpassen zonder beperkingen. Gebruik de Bewerklink om te bewerken binnen de openingsuren.
              </div>
              <div className="input-container" style={{ marginBottom: '1rem' }}>
                <label style={{ fontWeight: '500', marginRight: '0.5rem' }}>
                  Datum:
                </label>
                <input
                  type="date"
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                />
              </div>
              <div className="input-container" style={{ marginBottom: '1rem' }}>
                <label style={{ fontWeight: '500', marginRight: '0.5rem' }}>
                  Tijd:
                </label>
                <input
                  type="time"
                  value={newTime}
                  onChange={(e) => setNewTime(e.target.value)}
                />
              </div>
              {updateDateTimeError && (
                <div style={{ color: 'red', marginBottom: '0.5rem' }}>
                  {updateDateTimeError}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <button
                  className="button-style-3"
                  onClick={handleDateTimeUpdate}
                  disabled={isUpdatingDateTime}
                >
                  {isUpdatingDateTime ? 'Bezig...' : 'Opslaan'}
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Tooltip;
