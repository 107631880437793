// file-path: ./StripeConnectPage.jsx
import React, { useState, useEffect } from 'react';
import { FaStore } from 'react-icons/fa';
import { withHeader } from '../../Components/Structural/Header';
import useApi from '../../Hooks/useApi';

// Import the CSS file for styling
import './css/stripeConnectPage.css';

function StripeConnectPage() {
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const api = useApi();

  // On page load, check if there's a stored dashboardUrl
  useEffect(() => {
    const fetchDashboardUrl = async () => {
      try {
        const data = await api.get(`${window.baseDomain}api/account-link`, { noCache: true });
        if (data?.dashboardUrl) {
          setDashboardUrl(data.dashboardUrl);
        } else {
          setDashboardUrl('');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardUrl();
  }, [api]);

  /**
   * If there's no dashboardUrl after loading, reset the "stripeDashboardReloaded" localStorage.
   * This ensures that once we no longer have a stored dashboard, we allow future reloads
   * if it becomes available again.
   */
  useEffect(() => {
    if (!loading && !dashboardUrl) {
      localStorage.removeItem('stripeDashboardReloaded');
    }
  }, [loading, dashboardUrl]);

  /**
   * Whenever we have a valid dashboardUrl, open it in a new tab. But only reload to the root
   * once (the very first time a dashboard is opened), controlled by a localStorage flag.
   */
  useEffect(() => {
    if (dashboardUrl) {
      const hasReloaded = localStorage.getItem('stripeDashboardReloaded');
      if (!hasReloaded) {
        // Mark that we've done the reload once
        localStorage.setItem('stripeDashboardReloaded', 'true');

        // Open in a new tab
        window.open(dashboardUrl, '_blank');

        // Reload to the root so user sees no leftover state
        window.location.href = '/';
      }
    }
  }, [dashboardUrl]);

  /**
   * Creates or reuses a Stripe Connect account on the server,
   * returns an onboardingUrl, and opens it in a new tab.
   */
  const handleBeginOnboarding = async () => {
    try {
      setLoading(true);
      setError('');

      // POST /api/account-link -> returns { onboardingUrl, stripeAccountId }
      const data = await api.post(`${window.baseDomain}api/account-link`, {});
      if (!data.onboardingUrl) {
        throw new Error(data.error || 'Kon geen onboarding link ophalen');
      }

      // Open the onboarding URL in a new tab/window
      window.open(data.onboardingUrl, '_blank');
    } catch (err) {
      console.error(err);
      // If it's a 400 error, show "Maak eerst een account"
      if (err?.response?.status === 400) {
        setError('Maak eerst een account');
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Calls /api/account-link/verify to check if the account is fully verified.
   * If verified, we immediately set the returned dashboardUrl (which triggers the open & reload).
   */
  const handleVerifyOnboarding = async () => {
    try {
      setLoading(true);
      setError('');

      // POST /api/account-link/verify -> returns { dashboardUrl }
      const data = await api.post(`${window.baseDomain}api/account-link/verify`, {});
      if (data.dashboardUrl) {
        setDashboardUrl(data.dashboardUrl);
      } else {
        throw new Error(
          data.error || 'Account is niet geverifieerd. Sluit eerst de onboarding af in het andere tabblad.'
        );
      }
    } catch (err) {
      console.error(err);
      // If it's a 400 error, show "Maak eerst een account"
      if (err?.response?.status === 400) {
        setError('Maak eerst een account');
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="stripe-connect-page">
      {/* Show loading text if still fetching and there's no stored dashboard */}
      {loading && !dashboardUrl && <p>Bezig met laden...</p>}

      {/* If no dashboardUrl => show the form to begin onboarding & verify */}
      {!dashboardUrl && !loading && (
        <div className="stripe-connect-page create-section">
          <h2 className="stripe-connect-page title">Begin met Cadeaubonnen</h2>
          <p className="stripe-connect-page explanation">
            Connecteer uw bankaccount om te beginnen met cadeaubonnen te verkopen met 0% extra transactiekosten.
          </p>
          <button
            className="stripe-connect-page create-button button-style-3"
            onClick={handleBeginOnboarding}
            disabled={loading}
          >
            <FaStore style={{ marginRight: '8px' }} />
            Maak een verkopersaccount aan
          </button>
          <button
            style={{ marginTop: '20px' }}
            className="stripe-connect-page create-button button-style-3"
            onClick={handleVerifyOnboarding}
            disabled={loading}
          >
            Verifiëren
          </button>
          {error && <p className="stripe-connect-page error-message">{error}</p>}
        </div>
      )}
    </div>
  );
}

export default withHeader(StripeConnectPage);
