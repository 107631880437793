// src/components/GiftcardSettings/GiftcardPreview.jsx

import React, { useState, useEffect, useRef } from 'react';
import GiftcardLogoUploadComponent from './GiftcardLogoUploadComponent';
import { QRCodeCanvas } from 'qrcode.react';

const GiftcardPreview = ({ settings, handleChange }) => {
  const baseWidth = 800; // Original width of the A4
  const wrapperRef = useRef(null);
  const [scale, setScale] = useState(1); // Default scale is 1

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const wrapperWidth = entry.contentRect.width;
        const newScale = wrapperWidth / baseWidth;
        setScale(newScale);
      }
    });

    resizeObserver.observe(wrapper);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Format dates in Dutch
  const today = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(today.getFullYear() + 1);

  const dutchDateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
  const creationDateStr = today.toLocaleDateString('nl-NL', dutchDateOptions);
  const validUntilDateStr = nextYear.toLocaleDateString('nl-NL', dutchDateOptions);

  const EditableText = ({
    value,
    name,
    handleChange,
    element: Element = 'span',
    className = '',
    style = {},
    inputType = 'input',
    appendStaticText = ''
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
        if (inputType !== 'textarea') {
          inputRef.current.setSelectionRange(
            inputRef.current.value.length,
            inputRef.current.value.length
          );
        }
      }
    }, [isEditing, inputType]);

    const handleBlur = () => {
      setIsEditing(false);
      handleChange({ target: { name, value: localValue } });
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && inputType !== 'textarea') {
        handleBlur();
      }
    };

    const handleClick = () => {
      setIsEditing(true);
    };

    const grayBoxStyle = {
      border: '1px dashed gray',
      borderRadius: '8px',
      padding: '2px',
      display: 'block', // Always block for consistent width
      cursor: 'text',
      width: '100%',
      boxSizing: 'border-box',
      textAlign: 'center',
      color: '#000' // Ensure text is visible (fallback)
    };

    const inputStyle = {
      ...style,
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: '0',
      margin: '0',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      color: 'inherit',
      textAlign: 'center',
      width: '100%',
      boxSizing: 'border-box',
      display: 'block',
    };

    return (
      <span style={grayBoxStyle}>
        {isEditing ? (
          inputType === 'textarea' ? (
            <textarea
              ref={inputRef}
              name={name}
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              className={className}
              style={inputStyle}
              rows={4}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <input
              ref={inputRef}
              type="text"
              name={name}
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className={className}
              style={inputStyle}
              size={Math.max(localValue.length + 1, 1)}
            />
          )
        ) : (
          <Element
            className={className}
            style={{
              ...style,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              color: style.color || '#000' // If titleColor is not provided, fallback to black
            }}
            onClick={handleClick}
          >
            {localValue}{appendStaticText}
          </Element>
        )}
      </span>
    );
  };

  const innerStyle = {
    transform: `scale(${scale})`,
    color: '#000' // Ensure text color is black by default
  };

  // Determine logo container alignment based on settings.logoPosition
  const logoContainerStyle = {
    display: 'flex',
    width: '100%',
    justifyContent:
      settings.logoPosition === 'left'
        ? 'flex-start'
        : settings.logoPosition === 'right'
        ? 'flex-end'
        : 'center',
  };

  return (
    <div className="giftcard-preview-outer-container">
      <div className="giftcard-preview-scale-wrapper" ref={wrapperRef}>
        <div className="giftcard-preview-container-inner" style={innerStyle}>
          <div className="giftcard-logo-container" style={logoContainerStyle}>
            <GiftcardLogoUploadComponent />
          </div>

          <h1 className="giftcard-title">
            <EditableText
              value={settings.title}
              name="title"
              handleChange={handleChange}
              element="span"
              style={{ 
                fontWeight: 'bold', 
                fontSize: '36px', 
                color: settings.titleColor || '#000'
              }}
              appendStaticText=" €100"
            />
          </h1>

          <div className="giftcard-qr">
            <QRCodeCanvas value="https://example.com" size={128} />
          </div>

          <h2 className="giftcard-greeting">
            <EditableText
              value={settings.greeting}
              name="greeting"
              handleChange={handleChange}
              element="span"
              style={{ fontSize: '24px', color: '#000' }}
            />
          </h2>

          <p className="giftcard-paragraph">
            <EditableText
              value={settings.introText}
              name="introText"
              handleChange={handleChange}
              element="span"
              inputType="textarea"
              style={{ fontSize: '16px', lineHeight: '1.5', color: '#000' }}
            />
          </p>

          <p className="giftcard-paragraph">
            <EditableText
              value={settings.middleText}
              name="middleText"
              handleChange={handleChange}
              element="span"
              inputType="textarea"
              style={{ fontSize: '16px', lineHeight: '1.5', color: '#000' }}
            />
          </p>

          <p className="giftcard-paragraph">
            <EditableText
              value={settings.endText}
              name="endText"
              handleChange={handleChange}
              element="span"
              inputType="textarea"
              style={{ fontSize: '16px', lineHeight: '1.5', color: '#000' }}
            />
          </p>

          {/* Non-editable dates in Dutch */}
          <h3 className="giftcard-date" style={{ color: '#000' }}>
            Aanmaakdatum: {creationDateStr}
          </h3>
          <h3 className="giftcard-valid-date" style={{ color: '#000' }}>
            Geldig tot: {validUntilDateStr}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default GiftcardPreview;
