// file-path: ./SectionsNavigationBar.js
import React from 'react';
import { FaInfoCircle, FaUtensils, FaList, FaClock, FaImages, FaGift } from 'react-icons/fa';
import { motion, LayoutGroup } from 'framer-motion';
import './css/sectionsNavigationBar.css';

const iconMap = {
  FaInfoCircle: <FaInfoCircle />,
  FaUtensils: <FaUtensils />,
  FaList: <FaList />,
  FaClock: <FaClock />,
  FaImages: <FaImages />,
  // Added FaGift for the giftcard (cadeaubonnen) section
  FaGift: <FaGift />,
};

const SectionsNavigationBar = ({ sections, selectedSection, onSectionClick }) => {
  return (
    <div className="schedule-page navigation-bar">
      <div className="navigation-container">
        <LayoutGroup>
          {sections.map((section, index) => {
            const isSelected = selectedSection === section.id;
            return (
              <motion.div
                key={section.id}
                className={`restaurant-data-page navigation-item ${isSelected ? 'selected' : ''}`}
                onClick={() => onSectionClick(section.id)}
                whileHover={{ scale: 1.05 }}
                animate={{ scale: isSelected ? 1.1 : 1 }}
                layout
              >
                {isSelected && (
                  <motion.div
                    className="restaurant-data-page selected-day-background"
                    layoutId="selected-day-background"
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                  />
                )}
                <div className="restaurant-data-page icon">{iconMap[section.icon]}</div>
                <div className="restaurant-data-page label">{section.label}</div>
                {!isSelected && index < sections.length - 1 && (
                  <div className="restaurant-data-page border-right"></div>
                )}
              </motion.div>
            );
          })}
        </LayoutGroup>
      </div>
    </div>
  );
};

export default SectionsNavigationBar;
