// FAQEditor.js
import React from 'react';

const FAQEditor = ({ faqs = [], onChange }) => {
  // Define a function to handle changes in each FAQ
  const handleFaqChange = (index, field, value) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index][field] = value;
    onChange(updatedFaqs);
  };

  // Add a new, empty FAQ
  const addFaq = () => {
    onChange([...faqs, { question: '', answer: '' }]);
  };

  // Remove an FAQ by index
  const removeFaq = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs.splice(index, 1);
    onChange(updatedFaqs);
  };

  // Render
  return (
    <div className="restaurant-data-page faq-editor">
      {faqs.map((item, index) => (
        <div className="restaurant-data-page input-block gray-input-block" key={index}>
          <div className="input-container little-bottom-margin">
            <label>Vraag</label>
            <input
              type="text"
              value={item.question}
              onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
            />
          </div>
          <div className="restaurant-data-page input-container little-bottom-margin">
            <label>Antwoord</label>
            <textarea
              value={item.answer}
              onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
            ></textarea>
          </div>
          <button className="button standard-button delete-button" onClick={() => removeFaq(index)}>
            Verwijder
          </button>
        </div>
      ))}

      <button className="add-option" onClick={addFaq}>
        Voeg FAQ Toe
      </button>
    </div>
  );
};

export default FAQEditor;
