import React, { useState, useEffect } from 'react';
import useApi from '../../Hooks/useApi';
import SectionsNavigationBar from './SectionsNavigationBar';
import SectionContent from './SectionContent';
import './css/restaurantDataPage.css';
import './css/mobile.css'; // Mobile specific styles
import useNotification from '../../Components/Notification/index'; // Import the hook
import { withHeader } from '../../Components/Structural/Header';

const stripQuillFormatting = (html) => {
  if (!html) return '';
  // Replace <strong>...</strong> with ***...***
  let text = html.replace(/<strong>(.*?)<\/strong>/gi, '***$1***');
  // Remove all other HTML tags
  text = text.replace(/<[^>]+>/g, '');
  text = text.trim();
  return text;
};

const restoreQuillFormatting = (text) => {
  if (!text) return '';
  // Replace ***...*** back to <strong>...</strong>
  return text.replace(/\*\*\*(.*?)\*\*\*/g, '<strong>$1</strong>');
};

const RestaurantDataPage = () => {
  const api = useApi();
  const [selectedSection, setSelectedSection] = useState('general');
  const [dataExists, setDataExists] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const defaultRestaurantData = {
    general: {
      restaurantName: '',
      telephoneNumber: '',
      description: '',
      rating: 3,
      accentColor: '#c1926e',
      quote: 'Een culinaire ervaring om nooit te vergeten.',
      quote2: '',
      quote3: '',
      address: 'Grote Markt 1, 1000 Brussel',
      mapIframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80598.57537703653!2d4.2277725247881905!3d50.8551379427495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3a4ed73c76867%3A0xc18b3a66787302a7!2sBrussel!5e0!3m2!1snl!2sbe!4v1736158681643!5m2!1snl!2sbe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
      directions: 'Volg de A1 naar Brussel...',
      profilePicture: '',
      banner: '',
      gaultMillau: false,
      commissieBankDoorrekenen: true  // Added new field with default true
    },
    openingsuren: {
      openingHours: {
        Maandag: 'Gesloten',
        Dinsdag: '10:00 - 22:00',
        Woensdag: '10:00 - 22:00',
        Donderdag: '10:00 - 22:00',
        Vrijdag: '10:00 - 23:00',
        Zaterdag: '10:00 - 23:00',
        Zondag: '10:00 - 22:00',
      },
    },
    menus: {
      menuHighlights: [
        { title: 'Speciale Steak', description: 'Heerlijke steak...', image: 'steak.jpg' },
      ],
      description: ''
    },
    faq: [
      { question: 'Is er parking beschikbaar?', answer: 'Ja, wij hebben een ruime parking.' },
    ],
    gallery: {
      galleryImages: [],
      description: ''
    },
    cadeaubonnen: {
      banners: []
    },
    subpages: {
      faq: [],
      galleryImages: [],
      quote: '',
      address: '',
      mapIframe: '',
      directions: ''
    }
  };

  const [restaurantData, setRestaurantData] = useState(defaultRestaurantData);

  // -- RE-ENABLED the cadeaubonnen section below
  const sections = [
    { id: 'general', title: 'Algemeen', label: 'Algemeen', icon: 'FaInfoCircle' },
    { id: 'openingsuren', title: 'Openingsuren', label: 'Openingsuren', icon: 'FaClock' },
    { id: 'menus', title: "Menu's", label: "Menu's", icon: 'FaUtensils' },
    { id: 'faq', title: 'FAQ', label: 'FAQ', icon: 'FaList' },
    { id: 'gallery', title: 'Interieur', label: 'Interieur', icon: 'FaImages' },
    // New section for cadeaubonnen
    { id: 'cadeaubonnen', title: 'Cadeaubonnen', label: 'Cadeaubonnen', icon: 'FaGift' },
  ];

  const handleSectionClick = (sectionId) => {
    setSelectedSection(sectionId);
  };

  const { triggerNotification, NotificationComponent } = useNotification();

  // Fetch data once on mount and restore Quill formatting for description fields
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await api.get(window.baseDomain + 'api/restaurant-page');
        if (fetchedData && typeof fetchedData === 'object') {
          const restoredData = JSON.parse(JSON.stringify(fetchedData));

          // Restore only Quill fields
          if (restoredData.general?.description) {
            restoredData.general.description = restoreQuillFormatting(restoredData.general.description);
          }
          if (restoredData.gallery?.description) {
            restoredData.gallery.description = restoreQuillFormatting(restoredData.gallery.description);
          }
          if (restoredData.menus?.description) {
            restoredData.menus.description = restoreQuillFormatting(restoredData.menus.description);
          }
          if (Array.isArray(restoredData.menus?.menuHighlights)) {
            restoredData.menus.menuHighlights = restoredData.menus.menuHighlights.map((highlight) => {
              if (highlight.description) {
                highlight.description = restoreQuillFormatting(highlight.description);
              }
              return highlight;
            });
          }

          // Make sure gaultMillau is defined if missing
          if (restoredData.general && typeof restoredData.general.gaultMillau === 'undefined') {
            restoredData.general.gaultMillau = false;
          }

          // Make sure commissieBankDoorrekenen is defined if missing
          if (restoredData.general && typeof restoredData.general.commissieBankDoorrekenen === 'undefined') {
            restoredData.general.commissieBankDoorrekenen = true;
          }

          // Make sure cadeaubonnen structure is defined if missing
          if (!restoredData.cadeaubonnen) {
            restoredData.cadeaubonnen = { banners: [] };
          }

          setRestaurantData(restoredData);
          setDataExists(true);
        } else {
          setDataExists(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // No data yet, keep defaults
          setDataExists(false);
        } else {
          console.error('Error fetching restaurant-page data:', error);
        }
      } finally {
        setLoading(false); // Data has been fetched, we can stop loading
      }
    };
    fetchData();
  }, [api]);

  const handleSave = async () => {
    try {
      // Make a copy and strip formatting only for Quill fields before saving
      const cleanedData = JSON.parse(JSON.stringify(restaurantData));

      if (cleanedData.general?.description) {
        cleanedData.general.description = stripQuillFormatting(cleanedData.general.description);
      }
      if (cleanedData.gallery?.description) {
        cleanedData.gallery.description = stripQuillFormatting(cleanedData.gallery.description);
      }
      if (cleanedData.menus?.description) {
        cleanedData.menus.description = stripQuillFormatting(cleanedData.menus.description);
      }
      if (Array.isArray(cleanedData.menus?.menuHighlights)) {
        cleanedData.menus.menuHighlights = cleanedData.menus.menuHighlights.map((highlight) => {
          if (highlight.description) {
            highlight.description = stripQuillFormatting(highlight.description);
          }
          return highlight;
        });
      }

      triggerNotification('120 Seconden tot Publicatie', 'time');

      if (dataExists) {
        await api.put(window.baseDomain + 'api/restaurant-page', cleanedData);
      } else {
        await api.post(window.baseDomain + 'api/restaurant-page', cleanedData);
        setDataExists(true);
      }
      // Success message will appear after countdown in notification
    } catch (error) {
      console.error('Error saving restaurant-page data:', error);
      triggerNotification('Er is een fout opgetreden bij het opslaan van de data.', 'error');
    }
  };

  // If loading, show a loading spinner or message
  if (loading) {
    return (
      <div className="restaurant-data-page">
        <p>Loading data...</p>
      </div>
    );
  }

  return (
    <div className="restaurant-data-page">
      <NotificationComponent />
      <div className="restaurant-data-page schedule-page">
        <SectionsNavigationBar
          sections={sections}
          selectedSection={selectedSection}
          onSectionClick={handleSectionClick}
          restaurantData={restaurantData}
        />
        <SectionContent
          selectedSection={selectedSection}
          restaurantData={restaurantData}
          setRestaurantData={setRestaurantData}
          sections={sections}
          onSave={handleSave}
        />
      </div>
    </div>
  );
};

export default withHeader(RestaurantDataPage);
