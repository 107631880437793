// file-path: ../../Components/Notification/index.js
import { useState, useEffect, useRef } from 'react';
import NotificationPopover from './NotificationPopover';
import './css/animations.css';
import './css/notification.css';

const useNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const timeIntervalRef = useRef(null);

  const triggerNotification = (message, type) => {
    // For 'time' type, we handle differently: start countdown from 120
    if (type === 'time') {
      const id = new Date().getTime();
      setNotifications((prevNotifications) => [...prevNotifications, { id, message, type, countdown: 120 }]);
    } else {
      setNotifications((prevNotifications) => {
        const isDuplicate = prevNotifications.some(
          (n) => n.message === message && n.type === type
        );

        if (isDuplicate) {
          const updatedNotifications = prevNotifications.map((n) =>
            n.message === message && n.type === type
              ? { ...n, count: (n.count || 1) + 1 }
              : n
          );
          return updatedNotifications;
        }

        const id = new Date().getTime();
        return [...prevNotifications, { id, message, type }];
      });
    }
  };

  useEffect(() => {
    // Handle normal notifications removal after 3s
    // Time notifications will not be removed after 3s, they have their own logic
    const normalNotifications = notifications.filter((n) => n.type !== 'time');

    if (normalNotifications.length > 0) {
      const timer = setTimeout(() => {
        setNotifications((prevNotifications) => {
          // Remove the first non-time notification
          return prevNotifications.filter((n) => n.type === 'time');
        });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notifications]);

  useEffect(() => {
    // Handle time notification countdown
    // If we have a time notification, we count down every second
    const timeNotification = notifications.find((n) => n.type === 'time');
    if (timeNotification) {
      // Clear any existing interval
      if (timeIntervalRef.current) {
        clearInterval(timeIntervalRef.current);
      }

      // Start a new interval for the countdown
      timeIntervalRef.current = setInterval(() => {
        setNotifications((prevNotifications) => {
          return prevNotifications.map((n) => {
            if (n.type === 'time') {
              if (n.countdown > 1) {
                // Decrement countdown
                return { ...n, countdown: n.countdown - 1 };
              } else {
                // Countdown finished, remove time notification and show success message
                triggerNotification('Data Opgeslagen!', 'success');
                return null;
              }
            }
            return n;
          }).filter(Boolean);
        });
      }, 1000);

      return () => {
        if (timeIntervalRef.current) {
          clearInterval(timeIntervalRef.current);
        }
      };
    }
  }, [notifications]);

  const NotificationComponent = () => (
    <div className="notification-component">
      {notifications.map((notification) => (
        <NotificationPopover
          key={notification.id}
          message={notification.message}
          type={notification.type}
          countdown={notification.countdown}
        />
      ))}
    </div>
  );

  return { triggerNotification, NotificationComponent };
};

export default useNotification;
