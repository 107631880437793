import React, { useState } from 'react';
import useApi from '../../Hooks/useApi';
import { FaMagic } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/gallerySection.css';
import './css/mobile.css';

const convertToWebp = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          if (blob) {
            const webpFile = new File([blob], file.name.replace(/\.[^.]+$/, '.webp'), { type: 'image/webp' });
            resolve(webpFile);
          } else {
            reject(new Error('Failed to convert image to webp'));
          }
        }, 'image/webp', 0.9);
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const GallerySection = ({ restaurantData, setRestaurantData }) => {
  // Provide a default fallback structure
  const defaultGalleryData = {
    galleryImages: [],
    description: '',
  };

  // Use fallback if restaurantData.gallery is missing or undefined
  const [data, setData] = useState(restaurantData.gallery || defaultGalleryData);
  const api = useApi();

  const [aiLoading, setAiLoading] = useState(false);
  const [aiError, setAiError] = useState(null);

  const handleGalleryImageChange = async (index, file) => {
    if (!file) return;
    const webpFile = await convertToWebp(file);
    const formData = new FormData();
    formData.append('image', webpFile);

    try {
      const response = await api.post(window.baseDomain + `api/restaurant-page-images/gallery/${index}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // Safely spread out data.galleryImages (fallback to [])
      const updatedImages = [...(data.galleryImages || [])];
      updatedImages[index] = response.imageUrl;
      const updatedData = { ...data, galleryImages: updatedImages };
      setData(updatedData);
      setRestaurantData({ ...restaurantData, gallery: updatedData });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDescriptionChange = (value) => {
    const updatedData = { ...data, description: value };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, gallery: updatedData });
  };

  const addGalleryImage = () => {
    const updatedImages = [...(data.galleryImages || []), null];
    const updatedData = { ...data, galleryImages: updatedImages };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, gallery: updatedData });
  };

  const removeGalleryImage = (index) => {
    const updatedImages = [...(data.galleryImages || [])];
    updatedImages.splice(index, 1);
    const updatedData = { ...data, galleryImages: updatedImages };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, gallery: updatedData });
  };

  const generateDescription = async () => {
    setAiLoading(true);
    setAiError(null);
    try {
      const markupInstruction = "Je kunt woorden vetgedrukt maken met <strong>bold text</strong>. Zorg ervoor dat je minder dan 3 keer de <strong> tags toevoegd en dus enkel de meest belangrijke dingen markeert.";
      const prompt = data.description && data.description.trim() !== '' 
        ? `Verbeter en breid deze interieurbeschrijving uit: ${data.description}. Houdt het antwoord kort met maximum 15 zinnen. Dit is voor gastronomische restaurants. Gebruik geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}` 
        : `Geef een uitgebreide en aantrekkelijke interieurbeschrijving. Houdt het antwoord kort met maximum 15 zinnen. Dit is voor gastronomische restaurants. Gebruik geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}`;

      const response = await api.post(`${window.baseDomain}api/auth-gemini`, { prompt });
      if (response && response.response) {
        handleDescriptionChange(response.response);
      } else {
        setAiError('Geen geldige reactie van de AI gekregen.');
      }
    } catch (error) {
      console.error('Error generating gallery description with AI:', error);
      setAiError('Er is een fout opgetreden bij het ophalen van de beschrijving.');
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <div className="restaurant-data-page">
      {/* Safely map over galleryImages, falling back to [] if undefined */}
      {(data.galleryImages || []).map((img, index) => (
        <div className="restaurant-data-page input-block" key={index}>
          <div className="restaurant-data-page input-container image-input">
            <label>Afbeelding (onder 2MB)</label>
            <input
              type="file"
              onChange={(e) => handleGalleryImageChange(index, e.target.files[0])}
            />
            {img && (
              <img src={img} alt="Preview" className="restaurant-data-page image-preview" />
            )}
          </div>
          <button
            className="button standard-button delete-button"
            onClick={() => removeGalleryImage(index)}
          >
            Verwijder
          </button>
        </div>
      ))}

      <button
        className="restaurant-data-page add-option"
        onClick={addGalleryImage}
      >
        Voeg Afbeelding Toe
      </button>

      <div className="restaurant-data-page input-container">
        <label>Beschrijving voor het interieur</label>
        <ReactQuill 
          value={data.description || ''} 
          onChange={handleDescriptionChange}
          theme="snow"
          style={{ width: '100%' }}
        />
        <button 
          className="restaurant-data-page ai-assist-button" 
          onClick={generateDescription} 
          disabled={aiLoading}
        >
          {aiLoading ? 'AI Laden...' : <>Verbeter met AI</>}
        </button>
        {aiError && <div className="restaurant-data-page ai-error-message">{aiError}</div>}
      </div>
    </div>
  );
};

export default GallerySection;
