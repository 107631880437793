// file-path: ./Table.js

import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { FaSyncAlt, FaEdit, FaClone, FaTrash } from 'react-icons/fa';
import Reservation from './Reservation';
import './css/table.css';

/**
 * Main Table component
 */
const Table = ({
  // Table editing & action props:
  rotate,
  duplicate,
  deleteTable,
  editTable,         // <-- Provided by parent, triggers the parent's "open modal" logic
  showActions = true,
  rotation = 0,
  tableNumber,

  // Reservation-related props:
  reservations = [],
  removeReservation,
  updateNotes,
  numberOfGuests,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Local reservations assigned to this table
  const [tableReservations, setTableReservations] = useState(reservations);

  // Occupied if we have at least one reservation
  const isOccupied = tableReservations.length > 0;

  // React DnD drop handler to accept GUEST items
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: 'GUEST',
      drop: (draggedGuest) => {
        // Add the dragged guest if not already present
        setTableReservations((prev) => {
          if (prev.some((res) => res.id === draggedGuest.id)) {
            return prev;
          }
          return [...prev, draggedGuest];
        });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [tableReservations]
  );

  // Remove a reservation (locally + optional callback)
  const handleRemoveReservation = (reservationId) => {
    setTableReservations((prev) => prev.filter((res) => res.id !== reservationId));
    removeReservation?.(reservationId);
  };

  // Update notes (locally + optional callback)
  const handleUpdateNotes = (reservationId, newNotes) => {
    setTableReservations((prev) =>
      prev.map((res) => (res.id === reservationId ? { ...res, notes: newNotes } : res))
    );
    updateNotes?.(reservationId, newNotes);
  };

  /**
   * Adjust table size based on capacity
   */
  const dimensionCapacity = numberOfGuests === 1 ? 2 : numberOfGuests;
  const isSquare = dimensionCapacity === 4;
  const tableWidth = isSquare ? 70 : 70 + (dimensionCapacity - 4) * 15;
  const tableHeight = 70;

  // Calculate chairs
  const chairsPerSide = Math.ceil(numberOfGuests / 2);
  const topChairs = [];
  const bottomChairs = [];

  if (numberOfGuests === 1) {
    topChairs.push(0); // single chair top
  } else {
    for (let i = 0; i < chairsPerSide; i++) {
      topChairs.push(i);
      bottomChairs.push(i);
    }
  }

  // Dynamically adjust font size by the smaller dimension,
  // and rotate text so it's upright visually.
  const minDimension = Math.min(tableWidth, tableHeight);
  const dynamicFontSize = Math.max(10, Math.min(14, Math.floor(minDimension * 0.2)));

  const textRotationStyle = {
    transform: `rotate(${-rotation}deg)`,
    fontSize: `${dynamicFontSize}px`,
  };

  return (
    <div
      className={`floor-plan-page table-container ${isOccupied ? 'occupied' : ''}`}
      style={{ width: `${tableWidth}px`, height: `${tableHeight + 80}px` }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* NON-SCALED ACTION BUTTONS */}
      {showActions && isHovered && (
        <div className="floor-plan-page non-scaled action-buttons">
          <button
            className="floor-plan-page action-button rotate-button"
            onClick={rotate}
            aria-label="Rotate Table"
          >
            <FaSyncAlt color="#555" />
          </button>
          <button
            className="floor-plan-page action-button edit-button"
            onClick={editTable} // <-- This calls the parent's callback
            aria-label="Edit Table"
          >
            <FaEdit color="#555" />
          </button>
          <button
            className="floor-plan-page action-button duplicate-button"
            onClick={duplicate}
            aria-label="Duplicate Table"
          >
            <FaClone color="#555" />
          </button>
          <button
            className="floor-plan-page action-button delete-button"
            onClick={deleteTable}
            aria-label="Delete Table"
          >
            <FaTrash color="red" />
          </button>
        </div>
      )}

      {/* This is the scaled container that inherits rotation/scale */}
      <div
        className="floor-plan-page scaled-container"
        ref={dropRef}
        style={{
          transform: `rotate(${rotation}deg)`,
          transformOrigin: 'center center',
        }}
      >
        {/* Chairs on top side */}
        <div className="floor-plan-page chairs top-chairs" style={{ width: `${tableWidth}px` }}>
          {topChairs.map((_, index) => (
            <div key={`top-${index}`} className="floor-plan-page chair"></div>
          ))}
        </div>

        {/* The main table surface */}
        <div
          className={`floor-plan-page table ${isOccupied ? 'table-occupied' : ''}`}
          style={{ width: `${tableWidth}px`, height: `${tableHeight}px` }}
        >
          <div className="floor-plan-page table-text" style={textRotationStyle}>
            {tableReservations.map((res) => (
              <Reservation
                key={res.id}
                reservation={res}
                removeReservation={handleRemoveReservation}
                updateNotes={handleUpdateNotes}
                tableCapacity={numberOfGuests}
              />
            ))}
          </div>

          {tableNumber && (
            <div className="floor-plan-page table-number">T{tableNumber}</div>
          )}
        </div>

        {/* Chairs on bottom side */}
        <div
          className="floor-plan-page chairs bottom-chairs"
          style={{ width: `${tableWidth}px` }}
        >
          {bottomChairs.map((_, index) => (
            <div key={`bottom-${index}`} className="floor-plan-page chair"></div>
          ))}
        </div>
      </div>

      {/* NON-SCALED TOOLTIP */}
      {isOccupied && (
        <div className="floor-plan-page non-scaled tooltip">
          {tableReservations.map((res) => (
            <div key={res.id} className="floor-plan-page tooltip-content">
              <div className="floor-plan-page reservation-summary">
                {res.firstName} {res.lastName}
                <br /> {res.numberOfGuests}p - {res.time}
              </div>
              <div className="floor-plan-page reservation-notes">
                <input
                  type="text"
                  placeholder="Voeg een notitie toe..."
                  value={res.notes || ''}
                  onChange={(e) => handleUpdateNotes(res.id, e.target.value)}
                  aria-label={`Add a note for reservation of ${res.firstName} ${res.lastName}`}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Table;
