// file-path: ./index.js
import React, { useState, useEffect, useRef } from 'react';
import FloorPlan from './FloorPlan.js';
import Sidebar from './Sidebar.js';
import FloorPlanDimensionModal from './FloorPlanDimensionModal.js';
import { withHeader } from '../../../Components/Structural/Header/index.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaArrowsAlt } from 'react-icons/fa';

// IMPORTANT: Import the consolidated CSS
import './css/tableEditModalContent.css';
import './css/app.css';


// Import the modal and the content you want to show inside it
import TableEditModalContent from './TableEditModalContent.js';
import ModalWithoutTabs from '../../../Components/Structural/Modal/Standard/index.js';

const TableEditor = () => {
  const appContainerRef = useRef(null);

  // We track the base floor plan dimension in pixels (user sets via squares).
  const [floorPlanWidth, setFloorPlanWidth] = useState(800);
  const [floorPlanHeight, setFloorPlanHeight] = useState(600);

  // Show/hide the dimension modal
  const [showDimensionModal, setShowDimensionModal] = useState(false);

  // The forced scale to make the floor plan fill 100% width next to or without the sidebar
  const [forcedScale, setForcedScale] = useState(1);

  // Moved sidebar open state into this parent:
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // ----------------------------
  // 1) Table Editing (Lifted Up)
  // ----------------------------
  // If this is non-null, we show the edit modal.
  const [selectedElement, setSelectedElement] = useState(null);

  // A callback that FloorPlan or the Table can call to open the "Edit Table" modal
  const handleEditElement = (tableData) => {
    setSelectedElement(tableData);
  };

  // Called when user hits "save" in the TableEditModalContent
  const handleSaveElement = (updatedElement) => {
    console.log('Saving updated element => ', updatedElement);
    // Example: call an API endpoint to update DB
    // await api.post(`${window.baseDomain}api/update-table`, updatedElement);
    setSelectedElement(null); // Close modal on success
  };

  // Or if user cancels
  const handleCloseElementModal = () => {
    setSelectedElement(null);
  };

  // Adjust these if your sidebar is a different width
  const SIDEBAR_WIDTH = 350;

  useEffect(() => {
    function handleResize() {
      if (!appContainerRef.current) return;

      // 1) The total container width:
      const containerWidth = appContainerRef.current.offsetWidth;
      // 2) Subtract sidebar width only if the sidebar is open:
      const availableWidth = isSidebarOpen
        ? containerWidth - SIDEBAR_WIDTH
        : containerWidth;

      // 3) We want the floor plan's baseWidth to fill that availableWidth => scale
      const scale = availableWidth / floorPlanWidth;
      setForcedScale(scale);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once on mount

    // Re-run on dimension changes or sidebar toggle
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [floorPlanWidth, isSidebarOpen]);

  /**
   * Called from FloorPlanDimensionModal on every input change for W/H.
   */
  const handleChangeDimensions = (newPxWidth, newPxHeight) => {
    setFloorPlanWidth(newPxWidth);
    setFloorPlanHeight(newPxHeight);
  };

  /**
   * ============= Corner Cuts =============
   * We'll store corner cuts in px. The user sets them in squares in the modal,
   * we convert them to px.
   */
  const [cutCorners, setCutCorners] = useState({
    topLeft: { widthPx: 0, heightPx: 0 },
    topRight: { widthPx: 0, heightPx: 0 },
    bottomLeft: { widthPx: 0, heightPx: 0 },
    bottomRight: { widthPx: 0, heightPx: 0 },
  });

  const handleChangeCorners = (newCorners) => {
    setCutCorners({ ...newCorners });
  };

  // Example data for the sidebar
  const tables = [
    { id: 1, numberOfGuests: 1 },
    { id: 2, numberOfGuests: 2 },
    { id: 3, numberOfGuests: 3 },
    { id: 4, numberOfGuests: 4 },
    { id: 5, numberOfGuests: 5 },
    { id: 6, numberOfGuests: 6 },
    { id: 7, numberOfGuests: 7 },
    { id: 8, numberOfGuests: 8 },
  ];

  const walls = [
    { id: 1, length: 3 },
    { id: 2, length: 5 },
    { id: 3, length: 2 },
    { id: 4, length: 4 },
    { id: 5, length: 6 },
  ];

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="table-editor app-container"
        ref={appContainerRef}
      >
        {/* A button to open the dimension modal (Dutch). */}
        <div>
          <button
            onClick={() => setShowDimensionModal(true)}
            className="button-style-1"
            style={{position: "absolute", marginLeft: "10px", marginTop: "10px", zIndex: 10}}
          >
            <FaArrowsAlt />
          </button>
        </div>

        {/*
          FloorPlan receives:
            - baseWidth / baseHeight
            - forcedScale
            - cutCorners (the corner cut in px)
            - onEditElement for table editing
        */}
        <FloorPlan
          baseWidth={floorPlanWidth}
          baseHeight={floorPlanHeight}
          scale={forcedScale}
          cutCorners={cutCorners}
          onEditElement={handleEditElement}
        />

        <Sidebar
          tables={tables}
          walls={walls}
          isOpen={isSidebarOpen}
          onToggle={() => setIsSidebarOpen((prev) => !prev)}
        />
      </div>

      {showDimensionModal && (
        <FloorPlanDimensionModal
          currentWidth={floorPlanWidth}
          currentHeight={floorPlanHeight}
          onChangeDimensions={handleChangeDimensions}
          currentCutCorners={cutCorners}
          onChangeCorners={handleChangeCorners}
          onClose={() => setShowDimensionModal(false)}
        />
      )}

      {/* -------------------------------------- */}
      {/* 2) The Edit Modal, displayed from index */}
      {/* -------------------------------------- */}
      {selectedElement && (
        <ModalWithoutTabs
          onClose={handleCloseElementModal}
          content={
            <TableEditModalContent
              element={selectedElement}
              onSave={handleSaveElement}
              onClose={handleCloseElementModal}
            />
          }
        />
      )}
    </DndProvider>
  );
};

export default withHeader(TableEditor);
