// file-path: ./CadeaubonnenSection.js
import React, { useState } from 'react';
import useApi from '../../Hooks/useApi';

// Utility to convert images to WebP
const convertToWebp = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const webpFile = new File([blob], file.name.replace(/\.[^.]+$/, '.webp'), {
                type: 'image/webp',
              });
              resolve(webpFile);
            } else {
              reject(new Error('Failed to convert image to webp'));
            }
          },
          'image/webp',
          0.9
        );
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const CadeaubonnenSection = ({ restaurantData, setRestaurantData }) => {
  const api = useApi();

  const [banners, setBanners] = useState(restaurantData.cadeaubonnen?.banners || []);

  const handleBannerImageChange = async (index, file) => {
    if (!file) return;
    try {
      const webpFile = await convertToWebp(file);
      const formData = new FormData();
      formData.append('image', webpFile);

      const response = await api.post(
        `${window.baseDomain}api/restaurant-page-images/giftcards/${index}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      const updatedBanners = [...banners];
      updatedBanners[index].image = response.imageUrl || '';
      setBanners(updatedBanners);
      setRestaurantData({
        ...restaurantData,
        cadeaubonnen: {
          ...restaurantData.cadeaubonnen,
          banners: updatedBanners,
        },
      });
    } catch (error) {
      console.error('Error uploading cadeaubonnen banner image:', error);
    }
  };

  const handleBannerTitleChange = (index, title) => {
    const updatedBanners = [...banners];
    updatedBanners[index].title = title;
    setBanners(updatedBanners);
    setRestaurantData({
      ...restaurantData,
      cadeaubonnen: {
        ...restaurantData.cadeaubonnen,
        banners: updatedBanners,
      },
    });
  };

  const addBanner = () => {
    if (banners.length >= 9) return;
    const updatedBanners = [...banners, { title: '', image: '' }];
    setBanners(updatedBanners);
    setRestaurantData({
      ...restaurantData,
      cadeaubonnen: {
        ...restaurantData.cadeaubonnen,
        banners: updatedBanners,
      },
    });
  };

  const removeBanner = (index) => {
    const updatedBanners = [...banners];
    updatedBanners.splice(index, 1);
    setBanners(updatedBanners);
    setRestaurantData({
      ...restaurantData,
      cadeaubonnen: {
        ...restaurantData.cadeaubonnen,
        banners: updatedBanners,
      },
    });
  };

  return (
    <div className="restaurant-data-page cadeaubonnen-section">
      {banners.map((banner, index) => (
        <div className="restaurant-data-page input-block" key={index}>
          <div className="restaurant-data-page input-container banner-input">
            <label>Titel</label>
            <input
              type="text"
              value={banner.title}
              onChange={(e) => handleBannerTitleChange(index, e.target.value)}
            />
          </div>

          <div className="restaurant-data-page input-container banner-input">
            <label>Afbeelding (onder 2MB, 800px x 500px)</label>
            <input
              type="file"
              onChange={(e) => handleBannerImageChange(index, e.target.files[0])}
            />
            {banner.image && (
              <img
                src={banner.image}
                alt="Banner Preview"
                className="restaurant-data-page banner-image-preview"
              />
            )}
          </div>
          <button
            className="button standard-button delete-button"
            onClick={() => removeBanner(index)}
          >
            Verwijder
          </button>
        </div>
      ))}

      <button className="restaurant-data-page add-option" onClick={addBanner}>
        Voeg Banner Toe
      </button>
    </div>
  );
};

export default CadeaubonnenSection;
