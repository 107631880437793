import React, { useState } from 'react';
import useApi from '../../Hooks/useApi';
import { FaMagic } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/mobile.css'; // Mobile CSS import

const convertToWebp = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          if (blob) {
            const webpFile = new File([blob], file.name.replace(/\.[^.]+$/, '.webp'), { type: 'image/webp' });
            resolve(webpFile);
          } else {
            reject(new Error('Failed to convert image to webp'));
          }
        }, 'image/webp', 0.9);
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const GeneralSection = ({ restaurantData, setRestaurantData }) => {
  const api = useApi();
  const data = restaurantData.general || {};
  const [aiLoading, setAiLoading] = useState(false);
  const [aiError, setAiError] = useState(null);

  const handleChange = (field, value) => {
    const updatedData = { ...data, [field]: value };
    setRestaurantData({ ...restaurantData, general: updatedData });
  };

  const uploadImage = async (type, file) => {
    const webpFile = await convertToWebp(file);
    const formData = new FormData();
    formData.append('image', webpFile);
    try {
      const response = await api.post(`${window.baseDomain}api/restaurant-page-images/general/${type}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleImageChange = async (field, file) => {
    if (!file) return;
    const imageUrl = await uploadImage(field, file);
    if (imageUrl) {
      handleChange(field, imageUrl);
    }
  };

  const generateDescription = async () => {
    setAiLoading(true);
    setAiError(null);
    try {
      // Instructions for the AI about bold formatting:
      const markupInstruction = "Je kunt woorden vetgedrukt maken met <strong>bold text</strong>. Zorg ervoor dat je minder dan 3 keer de <strong> tags toevoegd en dus enkel de meest belangrijke dingen markeert.";
      const prompt = data.description && data.description.trim() !== '' 
        ? `Verbeter en breid deze restaurantbeschrijving uit: ${data.description}. Houd het antwoord kort met maximum 15 zinnen. Dit is voor gastronomische restaurants. Gebruik geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}` 
        : `Geef een uitgebreide en aantrekkelijke restaurantbeschrijving. Houd het antwoord kort met maximum 15 zinnen. Gebruik geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}`;

      const response = await api.post(`${window.baseDomain}api/auth-gemini`, { prompt });
      if (response && response.response) {
        handleChange('description', response.response);
      } else {
        setAiError('Geen geldige reactie van de AI gekregen.');
      }
    } catch (error) {
      console.error('Error generating description with AI:', error);
      setAiError('Er is een fout opgetreden bij het ophalen van de beschrijving.');
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <div className="restaurant-data-page">
      <div className="restaurant-data-page input-container">
        <label>Restaurant Naam</label>
        <input
          type="text"
          value={data.restaurantName || ''}
          onChange={(e) => handleChange('restaurantName', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container">
        <label>Mobiel nummer</label>
        <input
          type="text"
          value={data.telephoneNumber || ''}
          onChange={(e) => handleChange('telephoneNumber', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container">
        <label>Beschrijving</label>
        <ReactQuill 
          value={data.description || ''} 
          onChange={(value) => handleChange('description', value)}
          theme="snow"
          style={{ width: '100%' }}
        />
        <button 
          className="restaurant-data-page ai-assist-button" 
          onClick={generateDescription} 
          disabled={aiLoading}
        >
          {aiLoading ? 'AI Laden...' : <>Verbeter met AI</>}
        </button>
        {aiError && <div className="restaurant-data-page ai-error-message">{aiError}</div>}
      </div>

      <div className="restaurant-data-page input-container">
        <label>Rating (0-5)</label>
        <input
          type="number"
          min="0"
          max="5"
          value={data.rating || 0}
          onChange={(e) => handleChange('rating', parseInt(e.target.value, 10))}
        />
      </div>

      <div className="restaurant-data-page input-container">
        <label>Kleuraccent (Hex)</label>
        <input
          type="color"
          value={data.accentColor || '#ffffff'}
          onChange={(e) => handleChange('accentColor', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Quote 1</label>
        <input
          type="text"
          value={data.quote || ''}
          onChange={(e) => handleChange('quote', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Quote 2</label>
        <input
          type="text"
          value={data.quote2 || ''}
          onChange={(e) => handleChange('quote2', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Quote 3</label>
        <input
          type="text"
          value={data.quote3 || ''}
          onChange={(e) => handleChange('quote3', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Adres</label>
        <input
          type="text"
          value={data.address || ''}
          onChange={(e) => handleChange('address', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Map Iframe</label>
        <input
          type="text"
          value={data.mapIframe || ''}
          onChange={(e) => handleChange('mapIframe', e.target.value)}
        />
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Routebeschrijving</label>
        <input
          type="text"
          value={data.directions || ''}
          onChange={(e) => handleChange('directions', e.target.value)}
        />
      </div>

      {/* Aanwezig in de Gault Millau select box */}
      <div className="restaurant-data-page input-container margin-bottom">
        <label>Aanwezig in de Gault Millau</label>
        <select
          value={data.gaultMillau ? 'true' : 'false'}
          onChange={(e) => handleChange('gaultMillau', e.target.value === 'true')}
        >
          <option value="false">Nee</option>
          <option value="true">Ja</option>
        </select>
      </div>

      {/* New: Commissie Bank Doorrekenen select box */}
      <div className="restaurant-data-page input-container margin-bottom">
        <label>Commissie Bank Doorrekenen aan Klant</label>
        <select
          value={data.commissieBankDoorrekenen ? 'true' : 'false'}
          onChange={(e) => handleChange('commissieBankDoorrekenen', e.target.value === 'true')}
        >
          <option value="false">Nee</option>
          <option value="true">Ja</option>
        </select>
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Profielfoto (onder 2MB)</label>
        <input
          type="file"
          onChange={(e) => handleImageChange('profilePicture', e.target.files[0])}
        />
        {data.profilePicture && (
          <img
            src={data.profilePicture}
            alt="Profielfoto Voorbeeld"
            className="restaurant-data-page image-preview"
          />
        )}
      </div>

      <div className="restaurant-data-page input-container margin-bottom">
        <label>Banner (onder 2MB)</label>
        <input
          type="file"
          onChange={(e) => handleImageChange('banner', e.target.files[0])}
        />
        {data.banner && (
          <img
            src={data.banner}
            alt="Banner Voorbeeld"
            className="restaurant-data-page image-preview"
          />
        )}
      </div>
    </div>
  );
};

export default GeneralSection;
