// file-path: ./OverviewSectionFlex.js

import React, { useState, useEffect } from 'react';
import './css/overviewSection.css';
import SearchBar from './SearchBar';
import {
  FaSortUp,
  FaSortDown,
  FaSort,
  FaChevronLeft,
  FaChevronRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';
import useApi from '../../../../Hooks/useApi';

const OverviewSectionFlex = () => {
  const api = useApi();

  // Store the accountId and the charges array separately
  const [accountId, setAccountId] = useState('');
  const [charges, setCharges] = useState([]);

  // Searching: we allow search by (billing) name, email, and charge amount in euros
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [amountSearch, setAmountSearch] = useState('');

  // Sorting
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // We subtract 35 cents from the raw amount, then convert to euros.
  // e.g. if Stripe says 1000 (cents) => 10.00, we display => (1000 - 35) / 100 => 9.65
  const convertCentsToEuroMinus35 = (cents) => {
    if (typeof cents !== 'number') return 0;
    // Round to nearest hundred cents (e.g., 2234 -> 2200 or 2300) before converting to euros
    const roundedCents = Math.round(cents / 100) * 100;
    return roundedCents / 100.0;
  };
  

  useEffect(() => {
    const fetchCharges = async () => {
      try {
        console.log('Charges GET from last month');
        // The updated endpoint now returns { accountId, charges }
        // GET /api/stripe-payments/ (no accountId param in the URL)
        const data = await api.get(`${window.baseDomain}api/stripe-payments/`, {
          noCache: true,
        });

        /*
          Example of response structure:
          {
            "accountId": "acct_12345ABC",
            "charges": [ ...array of charge objects... ]
          }
        */
        if (data && data.charges && Array.isArray(data.charges)) {
          setAccountId(data.accountId || '');
          setCharges(data.charges);
        } else {
          console.error('Expected { accountId, charges } object, received:', data);
          setCharges([]);
        }
      } catch (error) {
        console.error('Error fetching charges:', error);
        setCharges([]); // Set to empty on error
      }
    };

    fetchCharges();
  }, [api]);

  // Handle sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  // Sort charges based on the chosen column and direction
  const getSortedCharges = (chargesArr) => {
    if (!Array.isArray(chargesArr)) return [];
    if (!sortedColumn) return chargesArr;

    return [...chargesArr].sort((a, b) => {
      let aVal = '';
      let bVal = '';

      switch (sortedColumn) {
        case 'name':
          aVal = a.billing_details?.name?.toLowerCase() || '';
          bVal = b.billing_details?.name?.toLowerCase() || '';
          break;
        case 'email':
          aVal = a.billing_details?.email?.toLowerCase() || '';
          bVal = b.billing_details?.email?.toLowerCase() || '';
          break;
        case 'amountEuro':
          // Convert to euro (minus 35c) and compare
          aVal = convertCentsToEuroMinus35(a.amount || 0);
          bVal = convertCentsToEuroMinus35(b.amount || 0);
          break;
        default:
          break;
      }

      if (aVal < bVal) return sortDirection === 'asc' ? -1 : 1;
      if (aVal > bVal) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Filter charges by name, email, and amount in euros
  // For the numeric search, we do a starts-with check on the euro string
  const getFilteredCharges = (chargesArr) => {
    if (!Array.isArray(chargesArr)) return [];

    return chargesArr.filter((charge) => {
      const name = (charge.billing_details?.name || '').toLowerCase();
      const email = (charge.billing_details?.email || '').toLowerCase();
      const euroValue = convertCentsToEuroMinus35(charge.amount || 0);
      // Turn it into a string with 2 decimals, e.g. "9.65"
      const euroStr = euroValue.toFixed(2);

      // Check if the first digits match for the amount
      const amountMatch =
        amountSearch === '' || euroStr.startsWith(amountSearch);

      const nameMatch = name.includes(nameSearch.toLowerCase());
      const emailMatch = email.includes(emailSearch.toLowerCase());

      return nameMatch && emailMatch && amountMatch;
    });
  };

  const sortedCharges = getSortedCharges(charges);
  const processedCharges = getFilteredCharges(sortedCharges);

  // Pagination calculations
  const totalPages = Math.ceil(processedCharges.length / itemsPerPage) || 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCharges = processedCharges.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page navigation
  const goToFirstPage = () => setCurrentPage(1);
  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToLastPage = () => setCurrentPage(totalPages);

  // Render sort icon
  const renderSortIcon = (column) => {
    if (sortedColumn !== column) {
      return <FaSort />;
    }
    return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  // Handle Export to CSV (example)
  const handleExport = () => {
    // Build CSV with the new "Cadeaubon link" column
    const headers = [
      'Charge ID',
      'Naam',
      'Email',
      'Bedrag in Euro (minus 0.35)',
      'Cadeaubon Link',
    ];

    const rows = processedCharges.map((charge) => {
      const euroValue = convertCentsToEuroMinus35(charge.amount || 0).toFixed(2);
      const cadeauLink = `https://uw-cadeaubon.vercel.app/verify/${accountId}/${charge.id}`;

      return [
        charge.id || '',
        charge.billing_details?.name || '',
        charge.billing_details?.email || '',
        euroValue,
        cadeauLink,
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers, ...rows].map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'stripe_charges.csv');
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="overview-section">
      <h2 className="overview-section__title">Cadeaubon Inkomsten</h2>

      {/* Search Bars */}
      <div className="search-bars-container">
        <SearchBar
          placeholder="Zoeken op naam"
          value={nameSearch}
          onChange={(e) => setNameSearch(e.target.value)}
          className="search-bar"
        />
        <SearchBar
          placeholder="Zoeken op email"
          value={emailSearch}
          onChange={(e) => setEmailSearch(e.target.value)}
          className="search-bar"
        />
        <SearchBar
          placeholder="Zoeken op bedrag (€)"
          value={amountSearch}
          onChange={(e) => setAmountSearch(e.target.value)}
          className="search-bar"
        />
      </div>

      <div className="overview-section__table-container">
        <table className="overview-section__table">
          <thead>
            <tr className="table-header-row">
              <th onClick={() => handleSort('name')} style={{ width: '20%' }}>
                Naam {renderSortIcon('name')}
              </th>
              <th onClick={() => handleSort('email')} style={{ width: '20%' }}>
                Email {renderSortIcon('email')}
              </th>
              <th onClick={() => handleSort('amountEuro')} style={{ width: '15%' }}>
                Bedrag in € {renderSortIcon('amountEuro')}
              </th>
              {/* New column: Cadeaubon link */}
              <th style={{ width: '30%' }}>Cadeaubon link</th>
            </tr>
          </thead>
          <tbody>
            {currentCharges.length > 0 ? (
              currentCharges.map((charge) => {
                const euroValue = convertCentsToEuroMinus35(charge.amount || 0).toFixed(2);
                // Build the Cadeaubon link using the accountId state
                const cadeauLink = `https://uw-cadeaubon.vercel.app/verify/${accountId}/${charge.id}`;

                return (
                  <tr key={charge.id} className="table-body-row">
                    <td>{charge.billing_details?.name || '—'}</td>
                    <td>{charge.billing_details?.email || '—'}</td>
                    <td>{euroValue}</td>
                    <td>
                      <a
                        href={cadeauLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Cadeaubon
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="no-data-row">
                <td colSpan="4" className="no-data">
                  Geen charges gevonden.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="overview-section__pagination">
        <div className="pagination__info">
          Pagina {currentPage} van {totalPages}
        </div>
        <div className="pagination__controls">
          <button
            onClick={goToFirstPage}
            disabled={currentPage === 1}
            className="pagination__button"
            title="Eerste Pagina"
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="pagination__button"
            title="Vorige Pagina"
          >
            <FaChevronLeft />
          </button>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="pagination__button"
            title="Volgende Pagina"
          >
            <FaChevronRight />
          </button>
          <button
            onClick={goToLastPage}
            disabled={currentPage === totalPages}
            className="pagination__button"
            title="Laatste Pagina"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
        <div className="export-button-container">
          <button className="button-style-3 button-export" onClick={handleExport}>
            Exporteer naar CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverviewSectionFlex;
