// file-path: ./components/AccountManage.jsx

import React, { useState, useEffect } from 'react';
import { FaUser, FaPhone, FaHome, FaCity, FaMapPin, FaUtensils, FaEnvelope } from 'react-icons/fa';
import FormField from './FormField';
import './css/accountManage.css';
import './css/mobile.css';

const AccountManage = ({ accountData, setAccountData, api, triggerNotification }) => {
  // Determine the greeting based on the current time
  const hour = new Date().getHours();
  let greeting;
  if (hour < 6) {
    greeting = 'Goedenacht 🌙';
  } else if (hour < 12) {
    greeting = 'Goedemorgen 👋'; 
  } else if (hour < 18) {
    greeting = 'Goedemiddag ☀️';
  } else {
    greeting = 'Goedenavond 😊';
  }

  const [formData, setFormData] = useState({
    first_name: accountData.first_name || '',
    last_name: accountData.last_name || '',
    email: accountData.email || '',
    phone_number: accountData.phone_number || '',
    street: accountData.street || '',
    house_number: accountData.house_number || '',
    city: accountData.city || '',
    postal_code: accountData.postal_code || '',
    bio: accountData.bio || '',
    imageId: accountData.imageId || '',
    restaurant_name: accountData.restaurant_name || '',
    // NEW FIELDS
    tripadvisor_review_link: accountData.tripadvisor_review_link || '',
    google_review_link: accountData.google_review_link || '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData({
      first_name: accountData.first_name || '',
      last_name: accountData.last_name || '',
      email: accountData.email || '',
      phone_number: accountData.phone_number || '',
      street: accountData.street || '',
      house_number: accountData.house_number || '',
      city: accountData.city || '',
      postal_code: accountData.postal_code || '',
      bio: accountData.bio || '',
      imageId: accountData.imageId || '',
      restaurant_name: accountData.restaurant_name || '',
      // Populate new fields if available
      tripadvisor_review_link: accountData.tripadvisor_review_link || '',
      google_review_link: accountData.google_review_link || '',
    });

    // Store the restaurant name in local storage if it exists
    if (accountData.restaurant_name) {
      localStorage.setItem('restaurantName', accountData.restaurant_name);
    }
  }, [accountData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (accountData._id) {
        // Update existing account
        await api.put(`${window.baseDomain}api/account`, formData);
        triggerNotification('Account bijgewerkt', 'success');
      } else {
        // Create new account
        await api.post(`${window.baseDomain}api/account`, formData);
        triggerNotification('Gegevens toegevoegd', 'success');
      }

      if (formData.restaurant_name) {
        localStorage.setItem('restaurantName', formData.restaurant_name);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      triggerNotification('Fout bij het opslaan', 'error');
    }
  };

  const handleLogout = () => {
    localStorage.setItem('loginSuccessful', false);
    localStorage.removeItem('restaurantName');
    window.location.reload();
  };

  return (
    <div className="profile-page">
      <h2 className="account-manage-title">{greeting}</h2>
      <div className="account-manage-container">
        <form className="account-manage-form" onSubmit={handleSave}>
          <FormField
            label="Voornaam"
            name="first_name"
            placeholder="Voornaam"
            value={formData.first_name}
            onChange={handleChange}
            icon={FaUser}
          />
          <FormField
            label="Achternaam"
            name="last_name"
            placeholder="Achternaam"
            value={formData.last_name}
            onChange={handleChange}
            icon={FaUser}
          />
          <FormField
            label="Email"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            icon={FaEnvelope}
          />
          <FormField
            label="Mobiel nummer"
            name="phone_number"
            type="tel"
            placeholder="Mobiel nummer"
            value={formData.phone_number}
            onChange={handleChange}
            icon={FaPhone}
          />
          <div className="form-row">
            <FormField
              label="Straat"
              name="street"
              placeholder="Straat"
              value={formData.street}
              onChange={handleChange}
              icon={FaHome}
              halfWidth
            />
            <FormField
              label="Huisnummer"
              name="house_number"
              placeholder="Huisnummer"
              value={formData.house_number}
              onChange={handleChange}
              icon={FaHome}
              halfWidth
            />
          </div>
          <div className="form-row">
            <FormField
              label="Stad"
              name="city"
              placeholder="Stad"
              value={formData.city}
              onChange={handleChange}
              icon={FaCity}
              halfWidth
            />
            <FormField
              label="Postcode"
              name="postal_code"
              placeholder="Postcode"
              value={formData.postal_code}
              onChange={handleChange}
              icon={FaMapPin}
              halfWidth
            />
          </div>
          <FormField
            label="Naam restaurant"
            name="restaurant_name"
            placeholder="Naam restaurant"
            value={formData.restaurant_name}
            onChange={handleChange}
            icon={FaUtensils}
          />

          {/* NEW FIELDS */}
          <FormField
            label="Tripadvisor Review Link"
            name="tripadvisor_review_link"
            placeholder="https://www.tripadvisor.com/..."
            value={formData.tripadvisor_review_link}
            onChange={handleChange}
            icon={FaUtensils}
          />
          <FormField
            label="Google Review Link"
            name="google_review_link"
            placeholder="https://g.page/..."
            value={formData.google_review_link}
            onChange={handleChange}
            icon={FaUtensils}
          />

          <div className="button-row">
            <button type="submit" className="button-style-3" disabled={loading}>
              {loading ? 'Opslaan...' : 'Opslaan'}
            </button>
            <button
              type="button"
              className="button-style-3 button-style-logout"
              onClick={handleLogout}
            >
              Uitloggen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountManage;
