// src/components/GiftcardSettings/GiftcardSettings.jsx

import React, { useState, useEffect, useMemo } from 'react';
import './css/giftcardSettings.css';
import { withHeader } from '../../Components/Structural/Header';
import useNotification from '../../Components/Notification';
import useApi from '../../Hooks/useApi';
import GeneralSettingsGiftcard from './GeneralSettingsGiftcard';
import GiftcardPreview from './GiftcardPreview';

const GiftcardSettings = () => {
  const api = useApi();
  const { triggerNotification, NotificationComponent } = useNotification();

  const defaultSettings = {
    title: 'Uw Cadeaubon',
    greeting: 'Beste Gast,',
    paragraphText: 'Bedankt voor uw aankoop. U kunt deze cadeaubon gebruiken om een fantastische ervaring te beleven.',
    logoPosition: 'center',
    introText: 'Dit is de introductietekst.',
    middleText: 'Dit is de middentekst.',
    endText: 'Dit is de eindtekst.',
    titleColor: '#000000'
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [initialSettings, setInitialSettings] = useState(defaultSettings);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        console.log("Giftcard Settings GET");
        const response = await api.get(
          window.baseDomain + 'api/giftcard-settings',
          { noCache: true }
        );
        const data = response || {};
        const mergedData = { ...defaultSettings, ...data };
        setSettings(mergedData);
        setInitialSettings(mergedData);
      } catch (err) {
        console.error('Error fetching giftcard settings:', err);
        triggerNotification(
          'Fout bij het ophalen van cadeaubon instellingen.',
          'error'
        );
        setSettings(defaultSettings);
        setInitialSettings(defaultSettings);
      }
    };

    fetchSettings();
  }, []); // Empty dependency array to prevent infinite calls

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await api.put(window.baseDomain + 'api/giftcard-settings', settings);
      triggerNotification('Instellingen opgeslagen', 'success');
      setInitialSettings(settings);
    } catch (err) {
      console.error('Error saving giftcard settings:', err);
      triggerNotification('Fout bij het opslaan', 'error');
    }
  };

  const isDirty = useMemo(
    () => JSON.stringify(settings) !== JSON.stringify(initialSettings),
    [settings, initialSettings]
  );

  return (
    <div className="giftcard-settings-page">
      <NotificationComponent />
      <div className="giftcard-settings-grid">
        {/* Giftcard Preview */}
        <div className="giftcard-preview-container">
          <GiftcardPreview settings={settings} handleChange={handleChange} />
        </div>

        {/* Settings Form */}
        <div className="giftcard-settings-container">
          <GeneralSettingsGiftcard
            settings={settings}
            handleChange={handleChange}
            handleSave={handleSave}
            isDirty={isDirty}
          />
        </div>
      </div>
    </div>
  );
};

export default withHeader(GiftcardSettings);
