// file-path: ./Utils/csvUtils.js

/**
 * A helper function to detect if a string is in the format "DD-MM-YYYY HH:mm".
 * If it is, we return { date: "YYYY-MM-DD", time: "HH:mm" }.
 * Otherwise, we return something for date/time even if partial or unparseable.
 */
export function parseDateTime(value) {
  // Example "27-03-2025 19:00"
  const regex = /^(\d{2})-(\d{2})-(\d{4})(?:\s+(\d{2}):(\d{2}))?$/;
  const match = (value || '').trim().match(regex);
  if (!match) {
    return { date: value || '', time: '' };
  }
  const [_, dd, mm, yyyy, hh, min] = match;
  const isoDate = `${yyyy}-${mm}-${dd}`; // e.g. "2025-03-27"
  const timeStr = (hh && min) ? `${hh}:${min}` : '';
  return { date: isoDate, time: timeStr };
}

/**
 * Build the final array of reservations.
 * - Skips rows with Status = CANCELLED (case-insensitive).
 * - Parses "Reservation date" -> rowObj.date & rowObj.time.
 * - Skips rows older than current date minus one month.
 * - Applies user-provided field mappings (guests -> integer, etc.).
 */
export function buildFinalData({ csvData, csvHeaders, mapping }) {
  // Identify "Reservation date" and "Status" columns
  const reservationDateColIndex = csvHeaders.indexOf('Reservation date');
  const statusColIndex = csvHeaders.indexOf('Status');

  // Calculate the cutoff date (1 month ago from right now)
  const now = new Date();
  const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());

  const finalArray = csvData
    .map((row) => {
      // A) Skip rows with Status = CANCELLED
      if (statusColIndex !== -1) {
        const rowStatus = row[statusColIndex];
        if (rowStatus && rowStatus.toUpperCase() === 'CANCELLED') {
          return null;
        }
      }

      // B) Build the base object from user mappings
      const rowObj = {};
      Object.keys(mapping).forEach((field) => {
        const colName = mapping[field] || '';
        const colIndex = csvHeaders.indexOf(colName);
        if (colIndex === -1) {
          rowObj[field] = '';
          return;
        }
        const cellValue = row[colIndex] || '';
        if (field === 'guests') {
          rowObj.guests = parseInt(cellValue, 10) || 0;
        } else {
          rowObj[field] = cellValue;
        }
      });

      // C) Parse "Reservation date"
      if (reservationDateColIndex !== -1) {
        const reservationDateValue = row[reservationDateColIndex] || '';
        const parsed = parseDateTime(reservationDateValue);
        rowObj.date = parsed.date;
        rowObj.time = parsed.time;
      } else {
        // If no "Reservation date" column, ensure these fields exist
        if (!('date' in rowObj)) rowObj.date = '';
        if (!('time' in rowObj)) rowObj.time = '';
      }

      // D) If the row has a valid date, skip it if it's older than 1 month ago
      if (rowObj.date) {
        const rowDate = new Date(rowObj.date); // parse "YYYY-MM-DD"
        if (!isNaN(rowDate.getTime()) && rowDate < oneMonthAgo) {
          return null; // skip
        }
      }

      // E) Fill in defaults
      rowObj.restaurantId = localStorage.getItem('username') || '';
      rowObj.reservationType = 'common';

      return rowObj;
    })
    .filter(Boolean); // Remove null rows (CANCELLED or older than 1 month)

  return finalArray; // This is guaranteed to be an array
}
