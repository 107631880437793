import React, { useState } from 'react';
import useApi from '../../Hooks/useApi';
import { FaMagic } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/mobile.css';

const convertToWebp = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          if (blob) {
            const webpFile = new File([blob], file.name.replace(/\.[^.]+$/, '.webp'), { type: 'image/webp' });
            resolve(webpFile);
          } else {
            reject(new Error('Failed to convert image to webp'));
          }
        }, 'image/webp', 0.9);
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const MenusSection = ({ restaurantData, setRestaurantData }) => {
  // Provide a default object structure for menus if it's missing.
  const defaultMenusData = {
    description: '',
    menuHighlights: [],
  };

  // Use a fallback: if restaurantData.menus is undefined, defaultMenusData is used.
  const [data, setData] = useState(restaurantData.menus || defaultMenusData);
  const api = useApi();

  const [aiLoading, setAiLoading] = useState(false);
  const [aiError, setAiError] = useState(null);

  const handleMenuChange = (index, field, value) => {
    // Always safely access `data.menuHighlights` (fallback to empty array if needed)
    const updatedHighlights = [...(data.menuHighlights || [])];
    updatedHighlights[index][field] = value;
    const updatedData = { ...data, menuHighlights: updatedHighlights };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, menus: updatedData });
  };

  const handleDescriptionChange = (value) => {
    const updatedData = { ...data, description: value };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, menus: updatedData });
  };

  const uploadImage = async (file, index) => {
    const webpFile = await convertToWebp(file);
    const formData = new FormData();
    formData.append('image', webpFile);

    try {
      const response = await api.post(window.baseDomain + `api/restaurant-page-images/menus/${index}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleImageChange = async (index, file) => {
    if (file) {
      const imageUrl = await uploadImage(file, index);
      if (imageUrl) {
        handleMenuChange(index, 'image', imageUrl);
      }
    }
  };

  const addMenuItem = () => {
    const updatedHighlights = [...(data.menuHighlights || []), { title: '', description: '', image: null }];
    const updatedData = { ...data, menuHighlights: updatedHighlights };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, menus: updatedData });
  };

  const removeMenuItem = (index) => {
    const updatedHighlights = [...(data.menuHighlights || [])];
    updatedHighlights.splice(index, 1);
    const updatedData = { ...data, menuHighlights: updatedHighlights };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, menus: updatedData });
  };

  const generateDescription = async () => {
    setAiLoading(true);
    setAiError(null);
    try {
      const markupInstruction = "Je kunt woorden vetgedrukt maken met <strong>bold text</strong>. Zorg ervoor dat je minder dan 3 keer de <strong> tags toevoegd en dus enkel de meest belangrijke dingen markeert.";
      const prompt = data.description && data.description.trim() !== '' 
        ? `Verbeter en breid deze menubeschrijving uit: ${data.description}. Houdt het antwoord kort met maximum 15 zinnen. Dit is voor gastronomische restaurants. Geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}` 
        : `Geef een uitgebreide en aantrekkelijke menubeschrijving. Houdt het antwoord kort met maximum 15 zinnen. Dit is voor gastronomische restaurants. Geen uitnodiging om te bestellen of vraag om te komen. Highlight speciale kenmerken met <strong>...</strong>. ${markupInstruction}`;

      const response = await api.post(`${window.baseDomain}api/auth-gemini`, { prompt });
      if (response && response.response) {
        handleDescriptionChange(response.response);
      } else {
        setAiError('Geen geldige reactie van de AI gekregen.');
      }
    } catch (error) {
      console.error('Error generating menu description with AI:', error);
      setAiError('Er is een fout opgetreden bij het ophalen van de beschrijving.');
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <div className="restaurant-data-page">
      {/* Safely map over menuHighlights, falling back to an empty array */}
      {(data.menuHighlights || []).map((item, index) => (
        <div className="restaurant-data-page input-block" key={index}>
          <div className="restaurant-data-page input-container">
            <label>Titel</label>
            <input
              type="text"
              value={item.title}
              onChange={(e) => handleMenuChange(index, 'title', e.target.value)}
            />
          </div>
          <div className="restaurant-data-page input-container">
            <label>Beschrijving</label>
            <ReactQuill
              value={item.description || ''}
              onChange={(value) => handleMenuChange(index, 'description', value)}
              theme="snow"
              style={{ width: '100%' }}
            />
          </div>
          <div className="restaurant-data-page input-container image-input">
            <label>Afbeelding (onder 2MB)</label>
            <input
              type="file"
              onChange={(e) => handleImageChange(index, e.target.files[0])}
            />
            {item.image && (
              <img src={item.image} alt="Preview" className="restaurant-data-page image-preview" />
            )}
          </div>
          <button 
            className="button standard-button delete-button" 
            onClick={() => removeMenuItem(index)}
          >
            Verwijder
          </button>
        </div>
      ))}
      <button className="restaurant-data-page add-option" onClick={addMenuItem}>
        Voeg Menu Item Toe
      </button>

      <div className="restaurant-data-page input-container">
        <label>Beschrijving voor alle menu's</label>
        <ReactQuill
          value={data.description || ''}
          onChange={handleDescriptionChange}
          theme="snow"
          style={{ width: '100%' }}
        />
        <button 
          className="restaurant-data-page ai-assist-button" 
          onClick={generateDescription} 
          disabled={aiLoading}
        >
          {aiLoading ? 'AI Laden...' : <>Verbeter met AI</>}
        </button>
        {aiError && <div className="restaurant-data-page ai-error-message">{aiError}</div>}
      </div>
    </div>
  );
};

export default MenusSection;
