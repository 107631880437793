// file-path: ./FloorPlan.js
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import useApi from '../../../Hooks/useApi.js';

import LinesLayer from './LinesLayer.js';
import FloorPlanElement from './FloorPlanElement.js';

// Custom hooks
import useElementActions from './Hooks/useElementsActions.js';
import useLineActions from './Hooks/useLineActions.js';

/**
 * getClipPath:
 * Creates a polygon with fully rectangular cuts (L-shaped),
 * only using horizontal/vertical segments (0°, 90°, 180°, 270°).
 * 
 * We'll define the shape in a clockwise direction from the top-left corner.
 */
function getClipPath(cutCorners, w, h) {
  if (!cutCorners) return 'none';

  // Extract the four corner cuts
  const tlw = cutCorners.topLeft?.widthPx || 0;
  const tlh = cutCorners.topLeft?.heightPx || 0;
  const trw = cutCorners.topRight?.widthPx || 0;
  const trh = cutCorners.topRight?.heightPx || 0;
  const blw = cutCorners.bottomLeft?.widthPx || 0;
  const blh = cutCorners.bottomLeft?.heightPx || 0;
  const brw = cutCorners.bottomRight?.widthPx || 0;
  const brh = cutCorners.bottomRight?.heightPx || 0;

  /**
   * Explanation of the shape (clockwise, from top-left):
   * 
   *  1. Start at (0,0)  --> Move right to (tlw, 0)   [top-left cut-out]
   *  2. Move down to (tlw, tlh)
   *  3. Then we go horizontally to (0, tlh)
   *  4. Then down to (0, h - blh)  [left edge -> bottom-left cut-out]
   *  5. Move right to (blw, h - blh)
   *  6. Then down to (blw, h)
   *  7. Then right to (w - brw, h) [bottom-right cut-out]
   *  8. Move up to (w - brw, h - brh)
   *  9. Then right to (w, h - brh)
   * 10. Then up to (w, trh)        [right edge -> top-right cut-out]
   * 11. Move left to (w - trw, trh)
   * 12. Then up to (w - trw, 0)
   * 13. Then left back to (tlw, 0) 
   * 
   * However, we need to carefully close the shape so we don't double-draw lines.
   * We'll break it down corner by corner, ensuring each corner is its own rectangular step.
   * 
   * The final polygon might have more or fewer vertices if some cuts are zero. 
   * But for simplicity, let's lay them all out.
   */

  // If we do EXACTLY the above, we create a shape that covers the "holes".
  // We want to invert it so that the cut-out corners *disappear*.
  // Actually, it's easier to define the "outer boundary" only.
  // Let's do it carefully from top-left (0,0) around the perimeter *outside the cutouts*.
  // 
  // We'll do it in segments:
  //
  // Top edge:   (0,0) -> (tlw, 0) -> (tlw, tlh) -> (w - trw, tlh) -> (w - trw, 0) -> (w, 0)
  // Right edge: (w, 0) -> (w, trh) -> (w, h - brh) -> (w, h)
  // Bottom edge: (w, h) -> (w - brw, h) -> (w - brw, h - brh) -> (blw, h - brh) -> (blw, h) -> (0, h)
  // Left edge:  (0, h) -> (0, h - blh) -> (0, tlh) -> (0, 0)
  // 
  // But we need to ensure we only keep the shape that excludes the rectangular corners.
  // Actually let's place the points in an order that fully outlines the cut shape.

  // Let's define corner positions to keep it consistent:
  // We'll build smaller "L" shapes for each corner if cut != 0, 
  // otherwise we just keep the normal corner.

  // For clarity, let's define the final polygon with 16 potential points (4 corners * 4 edges).
  // We'll skip or merge points if the cut is 0.

  // Start from top-left corner region, going clockwise:

  const points = [];

  // 1) Move from (0,0) horizontally to (tlw, 0)
  points.push(`${0}px ${0}px`);
  points.push(`${tlw + 3}px ${0}px`);

  // 2) If top-left cut is > 0, we go down to (tlw, tlh), then left to (0, tlh).
  //    This forms the rectangular corner. If tlw or tlh is 0, we'll skip it to avoid weird points.
  if (tlw > 0 && tlh > 0) {
    points.push(`${tlw + 3}px ${tlh + 3}px`);
    points.push(`${0}px ${tlh + 3}px`);
  }

  // 3) Move down to (0, h - blh) on the left edge
  points.push(`0px ${h - blh + 3}px`);

  // 4) If bottom-left cut is > 0, we move right to (blw, h - blh), then down to (blw, h).
  if (blw > 0 && blh > 0) {
    points.push(`${blw + 3}px ${h - blh  + 3}px`);
    points.push(`${blw  + 3}px ${h}px`);
  } else {
    // else just go directly to (0, h), because there's no cut
    points.push(`0px ${h}px`);
  }

  // 5) Move right to (w - brw, h)
  points.push(`${w - brw + 3}px ${h}px`);

  // 6) If bottom-right cut is > 0, go up to (w - brw, h - brh), then right to (w, h - brh).
  if (brw > 0 && brh > 0) {
    points.push(`${w - brw  + 3}px ${h - brh  + 3}px`);
    points.push(`${w}px ${h - brh  + 3}px`);
  } else {
    // else no cut => directly to (w, h)
    points.push(`${w}px ${h}px`);
  }

  // 7) Move up to (w, trh)
  points.push(`${w}px ${trh + 3}px`);

  // 8) If top-right cut is > 0, move left to (w - trw, trh), then up to (w - trw, 0).
  if (trw > 0 && trh > 0) {
    points.push(`${w - trw + 3}px ${trh + 3}px`);
    points.push(`${w - trw + 3}px ${0}px`);
  } else {
    // else no cut => directly to (w, 0)
    points.push(`${w}px ${0}px`);
  }

  // We close the shape, but the last point in polygon auto closes back to the first.
  // We'll remove duplicates if we generated them. A quick approach: filter out consecutive duplicates.

  // Convert array -> string
  const uniquePoints = [];
  for (let i = 0; i < points.length; i++) {
    if (i === 0 || points[i] !== points[i - 1]) {
      uniquePoints.push(points[i]);
    }
  }

  const polygonString = uniquePoints.join(', ');

  return `polygon(${polygonString})`;
}

const FloorPlan = ({
  scale = 1,
  baseWidth = 800,
  baseHeight = 600,
  cutCorners,
  onEditElement,
}) => {
  const [elements, setElements] = useState([]);
  const [lines, setLines] = useState([]);
  const [floorPlanSize, setFloorPlanSize] = useState({
    width: baseWidth,
    height: baseHeight,
  });

  useEffect(() => {
    setFloorPlanSize({ width: baseWidth, height: baseHeight });
  }, [baseWidth, baseHeight]);

  const floorPlanRef = useRef(null);
  const api = useApi();

  // For line drawing
  const [isDrawingLine, setIsDrawingLine] = useState(false);
  const [startTableId, setStartTableId] = useState(null);
  const [currentMousePosition, setCurrentMousePosition] = useState({ x: 0, y: 0 });
  const [isAltPressed, setIsAltPressed] = useState(false);

  // For naming new tables
  const [nextTableNumber, setNextTableNumber] = useState(1);

  // Hooks for managing elements and lines
  const {
    addElement,
    updateElement,
    moveElement,
    rotateElement,
    duplicateElement,
    deleteElement,
  } = useElementActions(setElements, setLines, floorPlanSize, 1, () => {}, api);
  const { addLine, deleteLine } = useLineActions(setLines, api);

  // Stop line-drawing on mouse up
  const handleMouseUp = useCallback(() => {
    if (isDrawingLine) {
      setIsDrawingLine(false);
      setStartTableId(null);
    }
  }, [isDrawingLine]);

  // Fetch tables & lines from API on mount
  useEffect(() => {
    const fetchTablesAndLines = async () => {
      try {
        console.log('TableEditor GET');
        const data = await api.get(`${window.baseDomain}api/tables`, { noCache: true });
        if (Array.isArray(data)) {
          setElements(data.filter((item) => item.type !== 'line'));
          setLines(data.filter((item) => item.type === 'line'));
        } else if (data && Array.isArray(data.tables)) {
          setElements(data.tables.filter((item) => item.type !== 'line'));
          setLines(data.tables.filter((item) => item.type === 'line'));
        } else {
          setElements([]);
          setLines([]);
        }
      } catch (error) {
        console.error('Error fetching tables:', error);
        setElements([]);
        setLines([]);
      }
    };
    fetchTablesAndLines();
  }, [api]);

  // ALT key detection
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Alt') setIsAltPressed(true);
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Alt') {
        setIsAltPressed(false);
        setIsDrawingLine(false);
        setStartTableId(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Mouse move tracking for line-drawing
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDrawingLine && floorPlanRef.current) {
        const rect = floorPlanRef.current.getBoundingClientRect();
        setCurrentMousePosition({
          x: (e.clientX - rect.left) / scale,
          y: (e.clientY - rect.top) / scale,
        });
      }
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDrawingLine, scale]);

  // Global mouse up
  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  // Snap for dragging
  const snapToGrid = (x, y, gridSize = 50) => {
    const snappedX = Math.round(x / gridSize) * gridSize;
    const snappedY = Math.round(y / gridSize) * gridSize;
    return [snappedX, snappedY];
  };

  // React-DnD drop
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item, monitor) => {
      if (!floorPlanRef.current) return;
      const offset = monitor.getClientOffset();
      const rect = floorPlanRef.current.getBoundingClientRect();

      let x = (offset.x - rect.left) / scale;
      let y = (offset.y - rect.top) / scale;

      if (item.elementType !== 'wall') {
        const [snappedX, snappedY] = snapToGrid(x, y);
        x = Math.max(0, Math.min(snappedX, floorPlanSize.width - item.width));
        y = Math.max(0, Math.min(snappedY, floorPlanSize.height - item.height));
      } else {
        x = Math.max(0, Math.min(x, floorPlanSize.width - item.width));
        y = Math.max(0, Math.min(y, floorPlanSize.height - item.height));
      }

      if (item.id) {
        moveElement(item.id, x, y);
      } else {
        const id = Date.now();
        const newElement = {
          id,
          type: item.elementType,
          subtype: item.subtype,
          x,
          y,
          width: item.width,
          height: item.height,
          capacity: item.capacity,
          name:
            item.elementType === 'table'
              ? `T${nextTableNumber}`
              : `${item.subtype.charAt(0).toUpperCase() + item.subtype.slice(1)} Decoration ${id}`,
          minCapacity: item.minCapacity || 1,
          maxCapacity: item.maxCapacity || 10,
          priority: 'Medium',
          rotation: 0,
        };

        if (item.elementType === 'table') {
          newElement.tableNumber = nextTableNumber;
          setNextTableNumber((prev) => prev + 1);
        }
        addElement(newElement);

        if (item.elementType === 'table' && onEditElement) {
          onEditElement(newElement);
        }
      }
    },
  });

  // For line-drawing from child elements
  const handleElementMouseDown = (elementId, e) => {
    if (!isAltPressed) return;
    e.stopPropagation();
    setIsDrawingLine(true);
    setStartTableId(elementId);

    if (floorPlanRef.current) {
      const rect = floorPlanRef.current.getBoundingClientRect();
      setCurrentMousePosition({
        x: (e.clientX - rect.left) / scale,
        y: (e.clientY - rect.top) / scale,
      });
    }
  };

  const handleElementMouseUp = (elementId) => {
    if (isDrawingLine && startTableId && startTableId !== elementId) {
      const newLine = {
        id: `line-${Date.now()}`,
        type: 'line',
        from: startTableId,
        to: elementId,
      };
      addLine(newLine);
    }
    setIsDrawingLine(false);
    setStartTableId(null);
  };

  const handleLineClick = (lineId) => {
    deleteLine(lineId);
  };

  return (
    <div
      className="table-editor floor-plan-scale-wrapper"
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }}
    >
      <div className="table-editor floor-plan-border-wrapper">
        <div
          id="floor-plan-container"
          className="table-editor floor-plan"
          ref={(node) => {
            floorPlanRef.current = node;
            drop(node);
          }}
          onMouseUp={handleMouseUp}
          style={{
            width: `${floorPlanSize.width}px`,
            height: `${floorPlanSize.height}px`,
            position: 'relative',
            clipPath: getClipPath(cutCorners, floorPlanSize.width, floorPlanSize.height),
          }}
        >
          <LinesLayer
            elements={elements}
            lines={lines}
            isDrawingLine={isDrawingLine}
            startTableId={startTableId}
            currentMousePosition={currentMousePosition}
            handleLineClick={handleLineClick}
          />
          {Array.isArray(elements) &&
            elements.map((el) => (
              <FloorPlanElement
                key={el.id}
                element={el}
                moveElement={moveElement}
                rotateElement={(id) => rotateElement(id)}
                duplicateElement={(id) => duplicateElement(id)}
                deleteElement={(id) => deleteElement(id)}
                floorPlanSize={floorPlanSize}
                tableNumber={el.tableNumber}
                openModal={(tableEl) => onEditElement && onEditElement(tableEl)}
                handleTableMouseDown={handleElementMouseDown}
                handleTableMouseUp={handleElementMouseUp}
                isAltPressed={isAltPressed}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;
