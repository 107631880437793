import React, { useEffect, useState } from 'react';
import ReservationNumber from './ReservationNumber.js';
import NameColumn from './NameColumn.js';
import Tooltip from './TooltipView.js'; // or './Tooltip.jsx', depending on your structure
import ConfirmationModal from '../../../../Components/Structural/Modal/Delete';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import './css/reservationRow.css';
import './css/mobile.css';
import useApi from '../../../../Hooks/useApi';

const FIELD_CONFIG = [
  { key: 'aantalGasten', label: '#', alwaysVisible: true },
  { key: 'tijdstip', label: 'Uur', alwaysVisible: true },
  { key: 'fullName', label: 'Naam', defaultVisible: true },
  { key: 'email', label: 'Email', defaultVisible: true },
  { key: 'phone', label: 'Mobiel nummer', defaultVisible: true },
  { key: 'menu', label: 'Menu', defaultVisible: false },
  // NEW: Status field
  { key: 'status', label: 'Status', defaultVisible: true },
];

const STATUS_COLORS = {
  Bevestigd: '#2ecc71',   // green
  Ingecheckt: '#3498db',  // blue
  Uitgecheckt: '#95a5a6', // gray
  'No Show': '#e74c3c',   // red
};

// Fallback status if none is provided
const DEFAULT_STATUS = 'Bevestigd';

const ReservationRow = ({
  reservation,
  visibleFields,
  isMobile,
  isTooltipOpen,
  onTooltipToggle,
  onTooltipClose,
  triggerNotification,
}) => {
  const seenKey = `seen-data-${reservation.id}`;
  const expiryTimeString = localStorage.getItem(seenKey);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  // --- Status editing ---
  const api = useApi();
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    reservation.status && reservation.status.trim() !== ''
      ? reservation.status
      : DEFAULT_STATUS
  );
  const statusColor = STATUS_COLORS[currentStatus] || STATUS_COLORS.Bevestigd;

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const handleStatusChange = async (newStatus) => {
    setIsStatusDropdownOpen(false);
    try {
      // Send PUT request to update status
      await api.put(`${window.baseDomain}api/auth-reservations/${reservation.id}`, {
        status: newStatus,
      });
      setCurrentStatus(newStatus);
      triggerNotification('Status succesvol bijgewerkt', 'success');
    } catch (error) {
      console.error('Error updating status:', error);
      triggerNotification('Fout bij het bijwerken van de status.', 'error');
    }
  };
  // ----------------------

  function getCurrentTimeInCEST() {
    const now = new Date();
    const nowInCESTString = now.toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    });
    return new Date(nowInCESTString);
  }

  let isNewReservationHere = false;

  if (expiryTimeString) {
    const expiryTime = new Date(expiryTimeString);
    const nowInCEST = getCurrentTimeInCEST();
    isNewReservationHere = nowInCEST < expiryTime;
  } else {
    isNewReservationHere = true;
  }

  useEffect(() => {
    if (!expiryTimeString) {
      const nowInCEST = getCurrentTimeInCEST();
      const expiryTime = new Date(nowInCEST.getTime() + 60 * 60 * 1000);
      localStorage.setItem(seenKey, expiryTime.toISOString());
    }
  }, [expiryTimeString, seenKey]);

  const handleDeleteSuccess = (deletedReservationId) => {
    if (deletedReservationId === reservation.id) {
      setIsVisible(false);
      triggerNotification('Reservatie verwijderd', 'success');
    }
  };

  if (!isVisible) {
    return null;
  }

  const renderField = (reservation, fieldKey) => {
    switch (fieldKey) {
      case 'aantalGasten':
        return <ReservationNumber aantalGasten={reservation.aantalGasten} />;
      case 'tijdstip':
        return reservation.tijdstip;
      case 'fullName':
        return (
          <NameColumn
            isNewReservationHere={isNewReservationHere}
            firstName={reservation.firstName}
            lastName={reservation.lastName}
          />
        );
      case 'email':
        return reservation.email;
      case 'phone':
        return reservation.phone;
      case 'menu':
        return reservation.menu;
      // NEW: status bubble
      case 'status':
        return (
          <div className="status-bubble-container">
            <div
              className="status-bubble"
              style={{ backgroundColor: statusColor }}
              onClick={toggleStatusDropdown}
            >
              {currentStatus}
            </div>
            {isStatusDropdownOpen && (
              <div className="status-dropdown">
                {Object.keys(STATUS_COLORS).map((statusOption) => (
                  <div
                    key={statusOption}
                    className="status-dropdown-item"
                    onClick={() => handleStatusChange(statusOption)}
                    style={{
                      backgroundColor: STATUS_COLORS[statusOption],
                      color: '#fff',
                    }}
                  >
                    {statusOption}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      default:
        return reservation[fieldKey];
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="reservation-row-mobile">
          {visibleFields.map((fieldKey) => (
            <div key={fieldKey} className="reservation-item">
              <div className="label">
                {FIELD_CONFIG.find((field) => field.key === fieldKey)?.label}
              </div>
              <div>{renderField(reservation, fieldKey)}</div>
            </div>
          ))}

          <div className="reservation-item">
            <div className="label">Extra Informatie</div>
            <div>{reservation.extra || 'Geen extra info'}</div>
          </div>
          <div className="reservation-item buttons-container">
            <button
              className="edit-button"
              onClick={() =>
                window.open(
                  `https://view.reservaties.net/?action=edit&reservationId=${encodeURIComponent(
                    reservation.id
                  )}&admin=true&restaurantId=${localStorage.getItem('username')}`,
                  '_blank'
                )
              }
            >
              <FaPencilAlt className="button-icon" />
              Bewerk
            </button>
            <button
              className="delete-button"
              onClick={() => setIsDeleteModalVisible(true)}
            >
              <FaTrashAlt className="button-icon" />
              Verwijderen
            </button>
          </div>

          <ConfirmationModal
            isVisible={isDeleteModalVisible}
            title="Reservatie Verwijderen"
            message="Wilt u deze reservatie verwijderen?"
            onConfirm={async () => {
              setIsDeleteModalVisible(false);
              setIsDeleting(true);
              setDeleteError(null);

              try {
                await api.delete(
                  `${window.baseDomain}api/auth-reservations/${reservation.id}`
                );
                handleDeleteSuccess(reservation.id);
              } catch (error) {
                console.error('Error deleting reservation:', error);
                setDeleteError(
                  error.response?.data?.error ||
                    error.message ||
                    'Failed to delete the reservation.'
                );
                triggerNotification(
                  'Fout bij het verwijderen van de reservatie.',
                  'error'
                );
              } finally {
                setIsDeleting(false);
              }
            }}
            onCancel={() => setIsDeleteModalVisible(false)}
            confirmText="Verwijderen"
            cancelText="Annuleren"
            confirmButtonClass="discard-button red"
            cancelButtonClass="cancel-button"
            isLoading={isDeleting}
            errorMessage={deleteError}
          />
        </div>
      ) : (
        <div className="reservation-row reservation-row-desktop">
          {visibleFields.map((fieldKey) => (
            <div key={fieldKey}>{renderField(reservation, fieldKey)}</div>
          ))}

          <Tooltip
            reservationId={reservation.id}
            extraInfo={reservation.extra}
            isTooltipOpen={isTooltipOpen}
            onTooltipToggle={onTooltipToggle}
            onTooltipClose={onTooltipClose}
            onDeleteSuccess={handleDeleteSuccess}
            triggerNotification={triggerNotification}
            currentGuests={reservation.aantalGasten}
            currentDate={reservation.date || ''}
            currentTime={reservation.tijdstip || ''}
          />
        </div>
      )}
    </>
  );
};

export default ReservationRow;
