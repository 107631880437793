import React, { useState } from 'react';
import './css/walls.css';

const Walls = ({ length }) => {
  const [rotation, setRotation] = useState(0); // Track rotation in degrees
  const [isDragging, setIsDragging] = useState(false); // Track dragging state

  const wallWidth = (length - 1) * 20;
  const wallHeight = 20;

  // Rotate wall by 90 degrees on click
  const handleRotate = () => {
    if (!isDragging) {
      setRotation((prev) => (prev + 90) % 360); // Increment rotation only on click
    }
  };

  const handleMouseDown = () => {
    setIsDragging(false); // Reset dragging state when mouse is pressed
  };

  const handleMouseMove = () => {
    setIsDragging(true); // Set dragging state if mouse is moved
  };

  const handleMouseUp = () => {
    if (!isDragging) {
      handleRotate(); // Rotate only if it wasn't a drag
    }
    setIsDragging(false); // Reset dragging state
  };

  return (
    <div
      className="table-plan-component walls-container"
      style={{
        width: `${wallWidth}px`,
        height: `${wallHeight}px`,
        transform: `rotate(${rotation}deg)`,
        transformOrigin: 'center',
        transition: 'transform 0.3s ease',
      }}
      onMouseDown={handleMouseDown} // Detect start of interaction
      onMouseMove={handleMouseMove} // Detect dragging
      onMouseUp={handleMouseUp} // Detect end of interaction
    >
      <div className="table-plan-component wall"></div>
    </div>
  );
};

export default Walls;
