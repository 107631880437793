// file-path: ./PaymentDataModal.js
import React, { useState } from 'react';
import './css/paymentDataModal.css';
import useApi from '../../../../Hooks/useApi';
import useNotification from '../../../../Components/Notification';

const PaymentDataModal = ({ data, accountId, paymentId, onClose }) => {
  const api = useApi();

  // Use the notification hook
  const { triggerNotification, NotificationComponent } = useNotification();

  // Store the entire payment data in state so we can update it immediately after a successful post
  const [paymentData, setPaymentData] = useState(data);

  // Input state for the new amount in euros
  const [newAmountInput, setNewAmountInput] = useState('');

  // We subtract 35 * 100 = 3500 from the amounts before converting to euros.
  const SUBTRACT_AMOUNT_CENTS = 35; // 35 euros in cents

  // Determine whether to subtract 35 based on metadata.commissieBankDoorrekenen
  const subtractAmount = paymentData.metadata?.commissieBankDoorrekenen === "false" ? 0 : SUBTRACT_AMOUNT_CENTS;

  // Convert original "amount" from cents to euro, minus 35 euros if applicable
  const aankoopwaardeEuro = (
    (paymentData.amount - subtractAmount) / 100
  ).toFixed(2);

  // Convert "metadata.new_amount" from cents to euro (if present), minus 35 euros
  const rawNewAmount = paymentData.metadata?.new_amount;
  // If metadata.new_amount is not set, assume the Balans is the same as the original purchase value minus 35 euros
  const balansCents = rawNewAmount
    ? parseInt(rawNewAmount, 10)
    : (paymentData.amount - SUBTRACT_AMOUNT_CENTS);
  const balansEuro = (balansCents / 100).toFixed(2);

  // Some billing details
  const billingName = paymentData.billing_details?.name || 'Onbekend';
  const billingEmail = paymentData.billing_details?.email || 'Onbekend';

  // The Stripe receipt URL, if available
  const receiptUrl = paymentData.receipt_url || '';

  // Handle posting the updated amount (multiply by 100 for Stripe)
  const handleUpdateAmount = async () => {
    const floatValue = parseFloat(newAmountInput);
    // Allow zero as a valid input
    if (isNaN(floatValue) || floatValue < 0) {
      triggerNotification('Voer een geldig bedrag in (bijv. 10.00)', 'warning');
      return;
    }

    // Convert "10.00" => 1000 (cents)
    const stripeAmount = Math.round(floatValue * 100);

    if (!accountId || !paymentId) {
      triggerNotification('Kan accountId of paymentId niet vinden.', 'error');
      return;
    }

    const postUrl = `${window.baseDomain}api/stripe-payments/${accountId}/${paymentId}`;

    try {
      await api.post(postUrl, { new_amount: stripeAmount.toString() });
      
      // Update the local state to reflect the new balance immediately
      const newMetadata = {
        ...paymentData.metadata,
        new_amount: stripeAmount.toString(),
      };
      setPaymentData({ ...paymentData, metadata: newMetadata });

      triggerNotification(`Bedrag succesvol aangepast naar: €${floatValue.toFixed(2)}`, 'success');
    } catch (err) {
      console.error(err);
      triggerNotification('Fout bij het updaten van de cadeaubon.', 'error');
    }
  };

  return (
    <div className="payment-container">
      <NotificationComponent />
      <h2 className="payment-data-modal__title">Betalingsgegevens</h2>

      {/* Always show the essential data in a nicely styled table */}
      <table className="gift-card-page payment-data-modal__table">
        <tbody>
          <tr>
            <th>Aankoopwaarde</th>
            <td>€{aankoopwaardeEuro}</td>
          </tr>
          <tr>
            <th>Balans</th>
            <td>€{balansEuro}</td>
          </tr>
          <tr>
            <th>Naam</th>
            <td>{billingName}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{billingEmail}</td>
          </tr>
        </tbody>
      </table>

      {/* Form to update the gift card amount */}
      <div className="gift-card-page payment-data-modal__form">
        <div className="form-group">
          <label htmlFor="newAmountInput">Nieuwe Balans in Euro (bijv. 10.00)</label>
          <div className="input-container">
            <input
              id="newAmountInput"
              type="number"
              step="0.01"
              value={newAmountInput}
              onChange={(e) => setNewAmountInput(e.target.value)}
            />
          </div>
        </div>

        <button
          type="button"
          className="button-style-3 update-button"
          onClick={handleUpdateAmount}
        >
          Update Bedrag
        </button>

        {receiptUrl && (
          <button
            type="button"
            className="button-style-3"
            onClick={() => window.open(receiptUrl, '_blank')}
          >
            Bekijk Betaalbewijs
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentDataModal;
