// file-path: ./config/csvConfig.js

// De velden die we willen mappen (behalve datum/tijd, die we automatisch kunnen detecteren)
export const staticFields = [
	'tables',
	'extraInfo',
	'phone',
	'email',
	'lastName',
	'firstName',
	'guests',
	'ipAddress',
	'language',
	'menu',
	'zitplaats',
  ];
  
  // Mogelijke voorinstellingen (presets). Je kunt er meer toevoegen indien gewenst.
  export const presetMappings = {
	none: {},
	formitable: {
	  tables: 'Tables',
	  extraInfo: 'Comments',
	  phone: 'Telephone',
	  email: 'Email',
	  lastName: 'First name',
	  firstName: 'Last name',
	  guests: 'Number of people',
	  ipAddress: '',
	  language: 'Language',
	  menu: '',
	  zitplaats: '',
	},
  };
  