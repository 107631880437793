// file-path: ./CsvUploadPage.jsx
import React, { useState } from 'react';
import Papa from 'papaparse';
import useApi from '../../Hooks/useApi';
import { withHeader } from '../../Components/Structural/Header';
import { buildFinalData } from './Utils/csvUtils'; 
import { staticFields, presetMappings } from './config/csvConfig';

// CSS
import './css/csvUploadPage.css';
import './css/mobile.css';

const CsvUploadPage = () => {
  const api = useApi();

  // CSV state
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  // Optional: user-defined mapping for other fields
  const [mapping, setMapping] = useState({});

  /**
   * Parse CSV with PapaParse when a file is selected
   */
  const handleCsvUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      delimiter: '', // auto-detect
      complete: (results) => {
        if (results?.data?.length > 0) {
          const [headerRow, ...rows] = results.data;
          setCsvHeaders(headerRow);
          setCsvData(rows);
        }
      },
      error: (err) => {
        console.error('Fout bij het parsen van CSV:', err);
      },
    });
  };

  /**
   * User selects a preset (optional, for other fields)
   */
  const handlePresetChange = (e) => {
    const presetValue = e.target.value;
    setMapping(presetMappings[presetValue] || {});
  };

  /**
   * User manually chooses a CSV column for a given field (except "Reservation date")
   */
  const handleMappingChange = (staticField, selectedCsvHeader) => {
    setMapping((prev) => ({
      ...prev,
      [staticField]: selectedCsvHeader,
    }));
  };

  /**
   * Build final data by calling buildFinalData from ./Utils/csvUtils.js
   */
  const buildFinalReservations = () => {
    return buildFinalData({
      csvData,
      csvHeaders,
      mapping,
    });
  };

  /**
   * Let the user download the final data as JSON
   */
  const handleProcess = () => {
    const finalData = buildFinalReservations();
    // finalData is an array
    const jsonString = JSON.stringify(finalData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'processed-data.json');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Upload the final reservations to the back-end
   * Endpoint: "/api/import-reservations"
   */
  const handleUploadToServer = async () => {
    try {
      const finalData = buildFinalReservations();

      // Debug: Check if finalData is indeed an array
      console.log('Final data to upload:', finalData);
      console.log('Is finalData an array?', Array.isArray(finalData));

      const response = await api.post(
        `${window.baseDomain}api/import-reservations`,
        finalData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response && response.insertedCount) {
        alert(`Succesvol geüpload: ${response.insertedCount} reservaties`);
      } else {
        alert('Upload is gelukt, maar geen insertedCount gevonden in de response');
      }
    } catch (error) {
      console.error('Fout bij het uploaden naar de server:', error);
      alert('Er trad een fout op tijdens het uploaden naar de server.');
    }
  };

  return (
    <div className="csv-upload-page">
      <h1 className="csv-upload-page__title">Kies een CSV-bestand</h1>

      {/* Upload CSV */}
      <div className="csv-upload-page__upload-container">
        <input
          type="file"
          accept=".csv"
          onChange={handleCsvUpload}
          className="csv-upload-page__file-input"
        />
      </div>

      {/* Preset selection */}
      <div className="csv-upload-page__preset-container">
        <label htmlFor="presetSelect" style={{ fontWeight: 500 }}>
          Voorinstelling:
        </label>
        <select
          id="presetSelect"
          onChange={handlePresetChange}
          className="csv-upload-page__select"
        >
          <option value="none">Geen</option>
          <option value="formitable">Formitable</option>
        </select>
      </div>

      {/* Mapping table (for other fields) */}
      {csvHeaders.length > 0 && (
        <div className="csv-upload-page__mapping-container">
          <h2 className="csv-upload-page__subtitle">
            CSV-kolommen toewijzen aan velden (behalve "Reservation date")
          </h2>
          <table className="csv-upload-page__mapping-table">
            <thead>
              <tr>
                <th className="csv-upload-page__mapping-table-header">Velden</th>
                <th className="csv-upload-page__mapping-table-header">
                  CSV-kolommen
                </th>
              </tr>
            </thead>
            <tbody>
              {staticFields.map((field) => (
                <tr key={field}>
                  <td className="csv-upload-page__mapping-table-field">
                    {field}
                  </td>
                  <td>
                    <select
                      className="csv-upload-page__select"
                      value={mapping[field] || ''}
                      onChange={(e) => handleMappingChange(field, e.target.value)}
                    >
                      <option value="">Selecteer een kolom</option>
                      {csvHeaders.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Buttons: Download JSON and Upload to Server */}
          <div className="csv-upload-page__button-wrapper">
            <button
              onClick={handleProcess}
              className="csv-upload-page__process-button"
            >
              Download JSON
            </button>

            <button
              onClick={handleUploadToServer}
              className="csv-upload-page__process-button"
              style={{ marginLeft: '10px', backgroundColor: '#4CAF50' }}
            >
              Upload naar Server
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withHeader(CsvUploadPage);
