// file-path: ./Sidebar.jsx

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import routesConfig from '../../../Config/sidebarConfig.js';
import {
  FaChevronRight,
  FaChevronLeft,
  FaPencilAlt,
  FaCog,
  FaQuestionCircle,
  FaBroom,
  FaCheck,
  FaDatabase,
  FaFile,
  FaComment,
  FaApple,
} from 'react-icons/fa';

import useApi from '../../../Hooks/useApi';
import './css/sidebar.css';
import './css/mobile.css';

const Sidebar = ({ onToggleExpand }) => {
  const isIframe = typeof window !== 'undefined' && window.isIframe;
  const [activeTab, setActiveTab] = useState(routesConfig[0].path);
  const [isExpanded, setIsExpanded] = useState(() => window.innerWidth >= 900);
  const [collapseTimeout, setCollapseTimeout] = useState(null);

  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();

  const handleStripeDashboard = async (path) => {
    try {
      setActiveTab(path);
      const data = await api.get(`${window.baseDomain}api/account-link`);
      if (data?.dashboardUrl) {
        window.open(data.dashboardUrl, '_blank');
      } else {
        navigate(path);
      }
      if (isExpanded) setIsExpanded(false);
      if (collapseTimeout) {
        clearTimeout(collapseTimeout);
        setCollapseTimeout(null);
      }
    } catch (err) {
      console.error(err);
      navigate(path);
    }
  };

  const colors = [
    '#48aaaf',
    '#4bb0b5',
    '#4fb5ba',
    '#53bbbf',
    '#56c0c4',
    '#5ac6c9',
    '#5ecbce',
    '#62d1d3',
    '#66d6d8',
    '#69dcdd',
  ];

  const handleItemClick = (path) => {
    if (path === '/stripe-dashboard') {
      handleStripeDashboard(path);
      return;
    }
    setActiveTab(path);
    navigate(path);
    if (isExpanded) setIsExpanded(false);
    if (collapseTimeout) {
      clearTimeout(collapseTimeout);
      setCollapseTimeout(null);
    }
  };

  const handleOntwerpClick = () => {
    const username = localStorage.getItem('username');
    const ontwerpUrl = 'https://preview.reservaties.net/?restaurantId=' + username + '&admin=true';
    window.open(ontwerpUrl, '_blank');
  };

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev);
    if (collapseTimeout) {
      clearTimeout(collapseTimeout);
      setCollapseTimeout(null);
    }
  };

  const handleMouseEnter = () => {
    if (collapseTimeout) {
      clearTimeout(collapseTimeout);
      setCollapseTimeout(null);
    }
  };

  const handleMouseLeave = () => {
    if (isExpanded) {
      const timeout = setTimeout(() => {
        setIsExpanded(false);
      }, 5000);
      setCollapseTimeout(timeout);
    }
  };

  useEffect(() => {
    return () => {
      if (collapseTimeout) clearTimeout(collapseTimeout);
    };
  }, [collapseTimeout]);

  useEffect(() => {
    const adjustPadding = () => {
      const elements = document.querySelectorAll('.withHeader');
      elements.forEach((el) => {
        el.style.paddingLeft = isExpanded ? '200px' : '60px';
      });
    };
    const timeoutId = setTimeout(adjustPadding, 0);
    return () => {
      clearTimeout(timeoutId);
      document.querySelectorAll('.withHeader').forEach((el) => {
        el.style.paddingLeft = '';
      });
    };
  }, [isExpanded, activeTab, location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.innerWidth < 900) {
        setIsExpanded(false);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (onToggleExpand) {
      onToggleExpand(isExpanded);
    }
  }, [isExpanded, onToggleExpand]);

  if (isIframe) {
    return null;
  }

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 900;

  // Filter routes for MODULES section
  const moduleRoutes = routesConfig.filter(
    (route) => route.isMenu && route.path !== '/restaurant-data'
  );

  return (
    <motion.div
      className={`sidebar-component ${isExpanded ? 'expanded' : ''}`}
      layout
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {moduleRoutes.map((route, index) => (
        <React.Fragment key={route.path}>
          <SidebarItem
            item={{ id: route.path, title: route.label, icon: route.icon }}
            activeTab={activeTab}
            handleItemClick={handleItemClick}
            isExpanded={isExpanded}
            activeColor={colors[index % colors.length]}
          />
          {index === 0 && isExpanded && !isMobile && (
            <div className="sidebar-subsection-title">MODULES</div>
          )}
        </React.Fragment>
      ))}

      {isExpanded && !isMobile && (
        <div className="sidebar-subsection-title">ONTWERP</div>
      )}

      {/* 1) Hardcoded “Ontwerp” button that opens preview in new tab */}
      <SidebarItem
        item={{ id: 'ontwerp', title: 'Ontwerp', icon: FaPencilAlt }}
        activeTab={activeTab}
        handleItemClick={handleOntwerpClick}
        isExpanded={isExpanded}
        activeColor={colors[0]}
      />

      {/* 2) NEW: “Uw Website” item with docLink example */}
      <SidebarItem
        item={{
          id: '/restaurant-page',
          title: 'Uw Website',
          icon: FaBroom,
        }}
        activeTab={activeTab}
        handleItemClick={() => handleItemClick('/restaurant-page')}
        isExpanded={isExpanded}
        activeColor={colors[3]}
      />

      {/* New APPS Section: Render only when sidebar is expanded and not on mobile */}
      {isExpanded && !isMobile && (
        <>
          <div className="sidebar-subsection-title">APPS</div>
          {/* Todo App Link */}
          <SidebarItem
            item={{ id: '/apps/todo', title: 'To Do', icon: FaCheck,
              docLink: 'https://mateza.slab.com/posts/wat-is-mateza-to-do-59qrj23b?shr=VAROCx3RKh9rpEqsyouy139Oe', // Example documentation link
             }}
            activeTab={activeTab}
            // Simple direct link, opening external URL
            handleItemClick={() => {
              window.location.href = 'https://mateza-todo.vercel.app/tasks';
            }}
            isExpanded={isExpanded}
            activeColor={colors[4]}
          />

          <SidebarItem
            item={{ id: '/apps/datahub', title: 'Datahub', icon: FaDatabase,
              docLink: 'https://mateza.slab.com/posts/wat-is-mateza-datahub-rt4c17n2?shr=N7iOQ6uN9jbhmRMza20uhPAw', // Example documentation link
             }}
            activeTab={activeTab}
            handleItemClick={() => {
              window.location.href = 'https://mateza-datahub.vercel.app/tasks';
            }}
            isExpanded={isExpanded}
            activeColor={colors[4]}
          />

          <SidebarItem
            item={{ id: '/apps/docs', title: 'Documents', icon: FaFile,
              docLink: 'https://mateza.slab.com/posts/wat-is-mateza-documents-7zwj1v8b?shr=v8jjunDMH8y3TmrbA16A4Nv5', // Example documentation link
             }}
            activeTab={activeTab}
            handleItemClick={() => {
              window.location.href = 'https://mateza-docs.vercel.app/dashboard';
            }}
            isExpanded={isExpanded}
            activeColor={colors[4]}
          />

          <SidebarItem
            item={{ id: '/apps/reviews', title: 'Reviews', icon: FaComment,
              docLink: 'https://mateza.slab.com/posts/wat-is-mateza-reviews-jeocbxl7?shr=gPTFjjAfAQ9xRlmNcm-wAkDb', // Example documentation link
             }}
            activeTab={activeTab}
            handleItemClick={() => {
              window.location.href = 'https://mateza-reviews.vercel.app/tasks';
            }}
            isExpanded={isExpanded}
            activeColor={colors[4]}
          />

          <SidebarItem
            item={{ id: '/apps/app', title: 'Mateza App', icon: FaApple,

             }}
            activeTab={activeTab}
            handleItemClick={() => {
              window.location.href = 'https://apps.apple.com/be/app/mateza-booking/id6740510109?l=nl';
            }}
            isExpanded={isExpanded}
            activeColor={colors[4]}
          />
        </>
      )}

      {isExpanded && !isMobile && (
        <div className="sidebar-subsection-title">ADMINISTRATIE</div>
      )}

      {/* Administratie Item */}
      <SidebarItem
        item={{ id: '/settings', title: 'Administratie', icon: FaCog }}
        activeTab={activeTab}
        handleItemClick={() => handleItemClick('/settings')}
        isExpanded={isExpanded}
        activeColor={colors[1]}
      />

      {/* HELP Section */}
      {isExpanded && !isMobile && (
        <div className="sidebar-subsection-title">Ondersteuning</div>
      )}
      <SidebarItem
        item={{ id: 'help', title: 'Gebruikersgids', icon: FaQuestionCircle }}
        activeTab={activeTab}
        handleItemClick={() =>
          window.open(
            'https://app.arcade.software/share/collections/THD59TuUd2VKU7gOzhEt',
            '_blank'
          )
        }
        isExpanded={isExpanded}
        activeColor={colors[2]}
      />

      <div className="sidebar-controls">
        <div className="sidebar-toggle-group">
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isExpanded ? <FaChevronLeft /> : <FaChevronRight />}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
