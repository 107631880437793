// file-path: ./SectionContent.js
import React from 'react';
import './css/sectionContent.css';
import GeneralSection from './GeneralSection';
import OpeningsurenSection from './OpeningsurenSection';
import MenusSection from './MenusSection';
import FAQEditor from './FAQEditor';
import GallerySection from './GallerySection';
import CadeaubonnenSection from './CadeaubonnenSection'; // <-- NEW IMPORT

const SectionContent = ({ selectedSection, restaurantData, setRestaurantData, sections, onSave }) => {
  const currentSection = sections.find(sec => sec.id === selectedSection);
  let content = null;

  switch (selectedSection) {
    case 'general':
      content = <GeneralSection restaurantData={restaurantData} setRestaurantData={setRestaurantData} />;
      break;
    case 'openingsuren':
      content = <OpeningsurenSection restaurantData={restaurantData} setRestaurantData={setRestaurantData} />;
      break;
    case 'menus':
      content = <MenusSection restaurantData={restaurantData} setRestaurantData={setRestaurantData} />;
      break;
    case 'faq':
      content = (
        <FAQEditor
          faqs={restaurantData.faq}
          onChange={(updatedFaqs) => setRestaurantData({ ...restaurantData, faq: updatedFaqs })}
        />
      );
      break;
    case 'gallery':
      content = <GallerySection restaurantData={restaurantData} setRestaurantData={setRestaurantData} />;
      break;
    // NEW CASE FOR CADEAUBONNEN
    case 'cadeaubonnen':
      content = <CadeaubonnenSection restaurantData={restaurantData} setRestaurantData={setRestaurantData} />;
      break;
    default:
      content = null;
  }

  return (
    <div
      className="restaurant-data-page"
      style={{ width: '80%', marginTop: '40px', display: 'flex', flexDirection: 'column' }}
    >
      <h2 className="schedule-page-title">{currentSection.title}</h2>
      <a
        href={`https://restaurants.mateza.be/${localStorage.getItem('username')}`}
        target="_blank"
        className="section-link"
        rel="noopener noreferrer"
        style={{ color: 'var(--color-blue)' }}
      >
        {`restaurants.mateza.be/${localStorage.getItem('username')}`}
      </a>
      <div className="restaurant-data-page day-content scheme-container">
        {content}
        <button className="button-style-3 save-button" onClick={onSave}>
          Opslaan
        </button>
      </div>
    </div>
  );
};

export default SectionContent;
