import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import './css/qrCodeScanner.css';
import './css/mobile.css';

import useApi from '../../../../Hooks/useApi';
import PaymentDataModal from './PaymentData';

const QRCodeScanner = ({ onClose }) => {
  const api = useApi();

  // Raw text from the scanned QR code
  const [scannedResult, setScannedResult] = useState(null);
  // Error messages to display
  const [errorMessage, setErrorMessage] = useState('');

  // Payment/giftcard details from GET
  const [paymentData, setPaymentData] = useState(null);
  // Whether to show the PaymentData in place (not a separate modal)
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // Store accountId & paymentId separately, so we can pass them to PaymentDataModal
  const [accountId, setAccountId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);

  // Debug info for printing each stage of processing
  const [debugInfo, setDebugInfo] = useState({
    rawUrl: '',
    pathSegments: [],
    finalUrl: '',
  });

  useEffect(() => {
    if (scannedResult) {
      // Prepare an object to hold debug information
      const tempDebug = {
        rawUrl: scannedResult,
        pathSegments: [],
        finalUrl: '',
      };

      try {
        // 1) Parse the scanned URL
        const url = new URL(scannedResult);

        // 2) Break path into segments
        const segments = url.pathname.split('/').filter(Boolean);
        tempDebug.pathSegments = segments;

        // We expect at least 3 segments, with the first = 'verify'
        if (segments.length >= 3 && segments[0] === 'verify') {
          const aId = segments[1];
          const pId = segments[2];

          // 3) Build the final GET endpoint
          const finalApiUrl = `${window.baseDomain}api/stripe-payments/${aId}/${pId}`;
          tempDebug.finalUrl = finalApiUrl;

          // Execute the GET request
          (async () => {
            try {
              const data = await api.get(finalApiUrl, { noCache: true });
              // Store the Stripe data
              setPaymentData(data);

              // Store the scanned accountId & paymentId
              setAccountId(aId);
              setPaymentId(pId);

              // Now show the PaymentData
              setShowPaymentModal(true);
            } catch (fetchError) {
              console.error(fetchError);
              setErrorMessage('Fout bij het ophalen van de betaling/giftcard.');
            }
          })();
        } else {
          setErrorMessage('Ongeldige QR-code of domein.');
        }
      } catch (e) {
        setErrorMessage('Ongeldige QR-code of URL.');
      } finally {
        setDebugInfo(tempDebug);
      }
    }
  }, [scannedResult, api]);

  // Called by QrReader whenever it detects a new scan or an error
  const handleScan = (result, error) => {
    if (result) {
      setScannedResult(result.text);
    }
    // Optional: handle error if needed
  };

  // We’ll show either the scanning UI or the payment data, not both.
  return (
    <div className="qr-scanner__container">
      {/* If we're NOT showing the payment data, display the scanner UI */}
      {!showPaymentModal && (
        <>
          <h3 className="qr-scanner__title">Scan QR Code</h3>
          <p className="qr-scanner__instructions">
            Richt uw camera op de QR code
          </p>

          <div className="qr-scanner__reader-wrapper">
            <QrReader
              onResult={handleScan}
              constraints={{ facingMode: 'environment' }}
              containerStyle={{ width: '100%' }}
            />
          </div>

          {errorMessage && (
            <p className="qr-scanner__error">
              {errorMessage}
            </p>
          )}
        </>
      )}

      {/* If we ARE showing the payment data, hide the scanner and show PaymentData */}
      {showPaymentModal && paymentData && (
        <div className="qr-scanner__payment-data">
          <PaymentDataModal
            data={paymentData}
            accountId={accountId}
            paymentId={paymentId}
            onClose={() => {
              setShowPaymentModal(false);
              setPaymentData(null);
              setScannedResult(null);
              setAccountId(null);
              setPaymentId(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
