// file-path: ./FloorPlanDimensionModal.js
import React, { useState, useEffect } from 'react';
import ModalWithoutTabs from '../../../Components/Structural/Modal/Standard/index.js';
import './css/floorPlanDimensionModal.css';

const SQUARE_SIZE = 50; // Each grid square is 50px

const FloorPlanDimensionModal = ({
  currentWidth,
  currentHeight,
  currentCutCorners,
  onChangeDimensions,
  onChangeCorners,
  onClose,
}) => {
  // Convert parent's current dimension to squares (rounded)
  const [tempWidthSquares, setTempWidthSquares] = useState(
    Math.round(currentWidth / SQUARE_SIZE)
  );
  const [tempHeightSquares, setTempHeightSquares] = useState(
    Math.round(currentHeight / SQUARE_SIZE)
  );

  // Convert parent's corners to squares
  const [tempCorners, setTempCorners] = useState({
    topLeft: {
      widthSquares: Math.round(currentCutCorners.topLeft.widthPx / SQUARE_SIZE),
      heightSquares: Math.round(currentCutCorners.topLeft.heightPx / SQUARE_SIZE),
    },
    topRight: {
      widthSquares: Math.round(currentCutCorners.topRight.widthPx / SQUARE_SIZE),
      heightSquares: Math.round(currentCutCorners.topRight.heightPx / SQUARE_SIZE),
    },
    bottomLeft: {
      widthSquares: Math.round(currentCutCorners.bottomLeft.widthPx / SQUARE_SIZE),
      heightSquares: Math.round(currentCutCorners.bottomLeft.heightPx / SQUARE_SIZE),
    },
    bottomRight: {
      widthSquares: Math.round(currentCutCorners.bottomRight.widthPx / SQUARE_SIZE),
      heightSquares: Math.round(currentCutCorners.bottomRight.heightPx / SQUARE_SIZE),
    },
  });

  // Store originals to revert on cancel
  const [originalWidthPx, setOriginalWidthPx] = useState(currentWidth);
  const [originalHeightPx, setOriginalHeightPx] = useState(currentHeight);
  const [originalCorners, setOriginalCorners] = useState({ ...currentCutCorners });

  useEffect(() => {
    setOriginalWidthPx(currentWidth);
    setOriginalHeightPx(currentHeight);
    setTempWidthSquares(Math.round(currentWidth / SQUARE_SIZE));
    setTempHeightSquares(Math.round(currentHeight / SQUARE_SIZE));

    setOriginalCorners({ ...currentCutCorners });
    setTempCorners({
      topLeft: {
        widthSquares: Math.round(currentCutCorners.topLeft.widthPx / SQUARE_SIZE),
        heightSquares: Math.round(currentCutCorners.topLeft.heightPx / SQUARE_SIZE),
      },
      topRight: {
        widthSquares: Math.round(currentCutCorners.topRight.widthPx / SQUARE_SIZE),
        heightSquares: Math.round(currentCutCorners.topRight.heightPx / SQUARE_SIZE),
      },
      bottomLeft: {
        widthSquares: Math.round(currentCutCorners.bottomLeft.widthPx / SQUARE_SIZE),
        heightSquares: Math.round(currentCutCorners.bottomLeft.heightPx / SQUARE_SIZE),
      },
      bottomRight: {
        widthSquares: Math.round(currentCutCorners.bottomRight.widthPx / SQUARE_SIZE),
        heightSquares: Math.round(currentCutCorners.bottomRight.heightPx / SQUARE_SIZE),
      },
    });
  }, [currentWidth, currentHeight, currentCutCorners]);

  const handleWidthChange = (value) => {
    const squares = parseInt(value || '0', 10);
    setTempWidthSquares(squares);
    onChangeDimensions(squares * SQUARE_SIZE, tempHeightSquares * SQUARE_SIZE);
  };

  const handleHeightChange = (value) => {
    const squares = parseInt(value || '0', 10);
    setTempHeightSquares(squares);
    onChangeDimensions(tempWidthSquares * SQUARE_SIZE, squares * SQUARE_SIZE);
  };

  const handleCornerChange = (corner, field, value) => {
    const squares = parseInt(value || '0', 10);
    const updatedCorners = { ...tempCorners };
    updatedCorners[corner][field] = squares;
    setTempCorners(updatedCorners);

    const newCornersPx = {
      topLeft: {
        widthPx: updatedCorners.topLeft.widthSquares * SQUARE_SIZE,
        heightPx: updatedCorners.topLeft.heightSquares * SQUARE_SIZE,
      },
      topRight: {
        widthPx: updatedCorners.topRight.widthSquares * SQUARE_SIZE,
        heightPx: updatedCorners.topRight.heightSquares * SQUARE_SIZE,
      },
      bottomLeft: {
        widthPx: updatedCorners.bottomLeft.widthSquares * SQUARE_SIZE,
        heightPx: updatedCorners.bottomLeft.heightSquares * SQUARE_SIZE,
      },
      bottomRight: {
        widthPx: updatedCorners.bottomRight.widthSquares * SQUARE_SIZE,
        heightPx: updatedCorners.bottomRight.heightSquares * SQUARE_SIZE,
      },
    };
    onChangeCorners(newCornersPx);
  };

  const handleCancel = () => {
    onChangeDimensions(originalWidthPx, originalHeightPx);
    onChangeCorners(originalCorners);
    onClose();
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <ModalWithoutTabs
      onClose={handleCancel}
      content={
        <div className="table-editor floor-plan-dimension-modal-content">
          <h2>Plattegrond Afmetingen Instellen</h2>

          <div className="dimension-form">
            {/* Width */}
            <div className="dimension-row-vertical">
              <label>Breedte (in vakken):</label>
              <input
                type="number"
                min="1"
                value={tempWidthSquares}
                onChange={(e) => handleWidthChange(e.target.value)}
              />
            </div>

            {/* Height */}
            <div className="dimension-row-vertical">
              <label>Hoogte (in vakken):</label>
              <input
                type="number"
                min="1"
                value={tempHeightSquares}
                onChange={(e) => handleHeightChange(e.target.value)}
              />
            </div>

            <h3>Hoek Afmetingen (in vakken)</h3>

            {/* Top Left Corner */}
            <div className="corner-row">
              <label>Hoek Linksboven:</label>
              <div className="inputs-wrapper">
                <input
                  type="number"
                  min="0"
                  value={tempCorners.topLeft.widthSquares}
                  onChange={(e) =>
                    handleCornerChange('topLeft', 'widthSquares', e.target.value)
                  }
                  placeholder="Breedte"
                />
                <input
                  type="number"
                  min="0"
                  value={tempCorners.topLeft.heightSquares}
                  onChange={(e) =>
                    handleCornerChange('topLeft', 'heightSquares', e.target.value)
                  }
                  placeholder="Hoogte"
                />
              </div>
            </div>

            {/* Top Right Corner */}
            <div className="corner-row">
              <label>Hoek Rechtsboven:</label>
              <div className="inputs-wrapper">
                <input
                  type="number"
                  min="0"
                  value={tempCorners.topRight.widthSquares}
                  onChange={(e) =>
                    handleCornerChange('topRight', 'widthSquares', e.target.value)
                  }
                  placeholder="Breedte"
                />
                <input
                  type="number"
                  min="0"
                  value={tempCorners.topRight.heightSquares}
                  onChange={(e) =>
                    handleCornerChange('topRight', 'heightSquares', e.target.value)
                  }
                  placeholder="Hoogte"
                />
              </div>
            </div>

            {/* Bottom Left Corner */}
            <div className="corner-row">
              <label>Hoek Linksonder:</label>
              <div className="inputs-wrapper">
                <input
                  type="number"
                  min="0"
                  value={tempCorners.bottomLeft.widthSquares}
                  onChange={(e) =>
                    handleCornerChange('bottomLeft', 'widthSquares', e.target.value)
                  }
                  placeholder="Breedte"
                />
                <input
                  type="number"
                  min="0"
                  value={tempCorners.bottomLeft.heightSquares}
                  onChange={(e) =>
                    handleCornerChange('bottomLeft', 'heightSquares', e.target.value)
                  }
                  placeholder="Hoogte"
                />
              </div>
            </div>

            {/* Bottom Right Corner */}
            <div className="corner-row">
              <label>Hoek Rechtsonder:</label>
              <div className="inputs-wrapper">
                <input
                  type="number"
                  min="0"
                  value={tempCorners.bottomRight.widthSquares}
                  onChange={(e) =>
                    handleCornerChange('bottomRight', 'widthSquares', e.target.value)
                  }
                  placeholder="Breedte"
                />
                <input
                  type="number"
                  min="0"
                  value={tempCorners.bottomRight.heightSquares}
                  onChange={(e) =>
                    handleCornerChange('bottomRight', 'heightSquares', e.target.value)
                  }
                  placeholder="Hoogte"
                />
              </div>
            </div>

            <div className="dimension-buttons">
              <button className="standard-button" onClick={handleCancel}>
                Annuleren
              </button>
              <button className="standard-button blue" onClick={handleSave}>
                Opslaan
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FloorPlanDimensionModal;
