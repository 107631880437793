import React, { useState } from 'react';

const OpeningsurenSection = ({ restaurantData, setRestaurantData }) => {
  // Provide a default fallback structure
  const defaultOpeningsurenData = {
    // Suppose we track all 7 days. You can adapt these keys to your actual data model.
    openingHours: {
      Maandag: '',
      Dinsdag: '',
      Woensdag: '',
      Donderdag: '',
      Vrijdag: '',
      Zaterdag: '',
      Zondag: '',
    },
  };

  // If restaurantData.openingsuren is missing, defaultOpeningsurenData is used.
  const [data, setData] = useState(restaurantData.openingsuren || defaultOpeningsurenData);

  const handleOpeningHoursChange = (day, hours) => {
    // Safely spread out data.openingHours (fallback to {})
    const updatedHours = { ...(data.openingHours || {}), [day]: hours };
    const updatedData = { ...data, openingHours: updatedHours };
    setData(updatedData);
    setRestaurantData({ ...restaurantData, openingsuren: updatedData });
  };

  // Safely get the days from the object keys (fallback to empty object).
  const days = Object.keys(data.openingHours || {});

  return (
    <>
      {days.map((day) => (
        <div className="input-container" key={day}>
          <label>{day}</label>
          <input
            type="text"
            value={data.openingHours[day] || ''}
            onChange={(e) => handleOpeningHoursChange(day, e.target.value)}
          />
        </div>
      ))}
    </>
  );
};

export default OpeningsurenSection;
